
/* ************** LEAFLET MAPS OPEN ************** */

/* required styles */
/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
/* hack that prevents hw layers "stretching" when loading new tiles */
/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
/* control positioning */
/* zoom and fade animations */
/* cursors */
/* marker & overlays interactivity */
/* visual tweaks */
/* general typography */
/* general toolbar styles */
/* zoom control */
/* layers control */
/* Default icon URLs */
/* attribution and scale controls */
/* popup */
/* div icon */
/* Tooltip */
/* Base styles for the element that has a tooltip */
/* Directions */
.leaflet-pane {
	position: absolute;
	inset-inline-start: 0;
	inset-block-start: 0;
	z-index: 400;

	>svg {
		position: absolute;
		inset-inline-start: 0;
		inset-block-start: 0;

		path {
			pointer-events: none;
		}

		path.leaflet-interactive {
			pointer-events: visiblePainted;
			pointer-events: auto;
		}
	}

	>canvas {
		position: absolute;
		inset-inline-start: 0;
		inset-block-start: 0;
	}
}

.leaflet-tile {
	position: absolute;
	inset-inline-start: 0;
	inset-block-start: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	filter: inherit;
	visibility: hidden;
}

.leaflet-marker-icon {
	position: absolute;
	inset-inline-start: 0;
	inset-block-start: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	display: block;
	pointer-events: none;
}

.leaflet-marker-shadow {
	position: absolute;
	inset-inline-start: 0;
	inset-block-start: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	display: block;
	pointer-events: none;
}

.leaflet-tile-container {
	position: absolute;
	inset-inline-start: 0;
	inset-block-start: 0;
	pointer-events: none;
}

.leaflet-zoom-box {
	position: absolute;
	inset-inline-start: 0;
	inset-block-start: 0;
	width: 0;
	height: 0;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	z-index: 800;
	border: 2px dotted #38f;
	background: $white-5;
}

.leaflet-image-layer {
	position: absolute;
	inset-inline-start: 0;
	inset-block-start: 0;
	pointer-events: none;
}

.leaflet-layer {
	position: absolute;
	inset-inline-start: 0;
	inset-block-start: 0;
}

.leaflet-container {
	overflow: hidden;
	-webkit-tap-highlight-color: transparent;
	background: #efefef;
	outline: 0;
	z-index: 0;
	font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;

	.leaflet-overlay-pane {
		svg {
			max-width: none !important;
			max-height: none !important;
		}
	}

	.leaflet-marker-pane {
		img {
			max-width: none !important;
			max-height: none !important;
		}
	}

	.leaflet-shadow-pane {
		img {
			max-width: none !important;
			max-height: none !important;
		}
	}

	.leaflet-tile-pane {
		img {
			max-width: none !important;
			max-height: none !important;
		}
	}

	img.leaflet-image-layer {
		max-width: none !important;
		max-height: none !important;
	}

	.leaflet-tile {
		max-width: none !important;
		max-height: none !important;
	}

	a {
		-webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
		color: #0078A8;
	}

	a.leaflet-active {
		outline: 2px solid orange;
	}

	.leaflet-control-attribution {
		background: $white;
		background: rgba(255, 255, 255, 0.7);
		margin: 0;
		font-size: 11px;
	}

	.leaflet-control-scale {
		font-size: 11px;
	}

	a.leaflet-popup-close-button {
		position: absolute;
		inset-block-start: 0;
		inset-inline-end: 0;
		padding: 4px 4px 0 0;
		border: none;
		text-align: center;
		width: 18px;
		height: 14px;
		font: 16px/14px Tahoma, Verdana, sans-serif;
		color: #c3c3c3;
		text-decoration: none;
		font-weight: bold;
		background: transparent;

		&:hover {
			color: #999;
		}
	}
}

.leaflet-safari {
	.leaflet-tile {
		image-rendering: -webkit-optimize-contrast;
	}

	.leaflet-tile-container {
		width: 1600px;
		height: 1600px;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

.leaflet-container.leaflet-touch-zoom {
	-ms-touch-action: pan-x pan-y;
	touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
	-ms-touch-action: pinch-zoom;
	touch-action: none;
	touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
	-ms-touch-action: none;
	touch-action: none;
}

.leaflet-tile-loaded {
	visibility: inherit;
}

.leaflet-overlay-pane {
	svg {
		-moz-user-select: none;
		user-select: none;
	}

	z-index: 400;
}

.leaflet-tile-pane {
	z-index: 200;
}

.leaflet-shadow-pane {
	z-index: 500;
}

.leaflet-marker-pane {
	z-index: 600;
}

.leaflet-tooltip-pane {
	z-index: 650;
}

.leaflet-popup-pane {
	z-index: 700;
	cursor: auto;
}

.leaflet-map-pane {
	canvas {
		z-index: 100;
	}

	svg {
		z-index: 200;
	}
}

.leaflet-vml-shape {
	width: 1px;
	height: 1px;
}

.lvml {
	behavior: url(#default#VML);
	display: inline-block;
	position: absolute;
}

.leaflet-control {
	position: relative;
	z-index: 800;
	pointer-events: visiblePainted;
	pointer-events: auto;
	float:$float-left;
	clear: both;
	cursor: auto;
}

.leaflet-top {
	position: absolute;
	z-index: 1000;
	pointer-events: none;
	inset-block-start: 0;

	.leaflet-control {
		margin-block-start: 10px;
	}
}

.leaflet-bottom {
	position: absolute;
	z-index: 1000;
	pointer-events: none;
	inset-block-end: 0;

	.leaflet-control {
		margin-block-end: 10px;
	}

	.leaflet-control-scale {
		margin-block-end: 5px;
	}
}

.leaflet-right {
	inset-inline-end: 0;

	.leaflet-control {
		float:$float-right;
		margin-inline-end: 10px;
	}
}

.leaflet-left {
	inset-inline-start: 0;

	.leaflet-control {
		margin-inline-start: 10px;
	}

	.leaflet-control-scale {
		margin-inline-start: 5px;
	}
}

.leaflet-fade-anim {
	.leaflet-tile {
		will-change: opacity;
	}

	.leaflet-popup {
		opacity: 0;
		-webkit-transition: opacity 0.2s linear;
		-moz-transition: opacity 0.2s linear;
		transition: opacity 0.2s linear;
	}

	.leaflet-map-pane {
		.leaflet-popup {
			opacity: 1;
		}
	}
}

.leaflet-zoom-animated {
	-webkit-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
}

.leaflet-zoom-anim {
	.leaflet-zoom-animated {
		will-change: transform;
		-webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
		-moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
		transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
	}

	.leaflet-tile {
		-webkit-transition: none;
		-moz-transition: none;
		transition: none;
	}

	.leaflet-zoom-hide {
		visibility: hidden;
	}
}

.leaflet-pan-anim {
	.leaflet-tile {
		-webkit-transition: none;
		-moz-transition: none;
		transition: none;
	}
}

.leaflet-interactive {
	cursor: pointer;
}

.leaflet-grab {
	cursor: -webkit-grab;
	cursor: -moz-grab;
	cursor: grab;
}

.leaflet-crosshair {
	cursor: crosshair;

	.leaflet-interactive {
		cursor: crosshair;
	}
}

.leaflet-dragging {
	.leaflet-grab {
		cursor: move;
		cursor: -webkit-grabbing;
		cursor: -moz-grabbing;
		cursor: grabbing;

		.leaflet-interactive {
			cursor: move;
			cursor: -webkit-grabbing;
			cursor: -moz-grabbing;
			cursor: grabbing;
		}
	}

	.leaflet-marker-draggable {
		cursor: move;
		cursor: -webkit-grabbing;
		cursor: -moz-grabbing;
		cursor: grabbing;
	}
}

.leaflet-marker-icon.leaflet-interactive {
	pointer-events: visiblePainted;
	pointer-events: auto;
}

.leaflet-image-layer.leaflet-interactive {
	pointer-events: visiblePainted;
	pointer-events: auto;
}

.leaflet-bar {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
	border-radius: 4px;

	a {
		background-color: $white;
		border-block-end: 1px solid #ccc;
		width: 26px;
		height: 26px;
		line-height: 26px;
		display: block;
		text-align: center;
		text-decoration: none;
		color: black;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		display: block;

		&:hover {
			background-color: $white;
			border-block-end: 1px solid #ccc;
			width: 26px;
			height: 26px;
			line-height: 26px;
			display: block;
			text-align: center;
			text-decoration: none;
			color: black;
			background-color: #f4f4f4;
		}

		&:first-child {
			border-start-end-radius: 4px;
			border-start-end-radius: 4px;
		}

		&:last-child {
			border-end-start-radius: 4px;
			border-end-end-radius: 4px;
			border-block-end: none;
		}
	}

	a.leaflet-disabled {
		cursor: default;
		background-color: #f4f4f4;
		color: #bbb;
	}
}

.leaflet-control-layers-toggle {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	display: block;
	background-image: url(images/layers.png);
	width: 36px;
	height: 36px;
}

.leaflet-touch {
	.leaflet-bar {
		a {
			width: 30px;
			height: 30px;
			line-height: 30px;

			&:first-child {
				border-start-end-radius: 2px;
				border-start-end-radius: 2px;
			}

			&:last-child {
				border-end-start-radius: 2px;
				border-end-end-radius: 2px;
			}
		}

		box-shadow: none;
		border: 1px solid $black-2;
		background-clip: padding-box;
	}

	.leaflet-control-zoom-in {
		font-size: 22px;
	}

	.leaflet-control-zoom-out {
		font-size: 22px;
	}

	.leaflet-control-layers-toggle {
		width: 44px;
		height: 44px;
	}

	.leaflet-control-attribution {
		box-shadow: none;
	}

	.leaflet-control-layers {
		box-shadow: none;
		border: 1px solid $black-2;
		background-clip: padding-box;
	}
}

.leaflet-control-zoom-in {
	font: bold 18px 'Lucida Console', Monaco, monospace;
	text-indent: 1px;
}

.leaflet-control-zoom-out {
	font: bold 18px 'Lucida Console', Monaco, monospace;
	text-indent: 1px;
}

.leaflet-control-layers {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
	background: $white;
	border-radius: 5px;

	.leaflet-control-layers-list {
		display: none;
	}

	label {
		display: block;
	}
}

.leaflet-retina {
	.leaflet-control-layers-toggle {
		background-image: url(images/layers-2x.png);
		background-size: 26px 26px;
	}
}

.leaflet-control-layers-expanded {
	.leaflet-control-layers-toggle {
		display: none;
	}

	.leaflet-control-layers-list {
		display: block;
		position: relative;
	}

	padding: 6px 10px 6px 6px;
	color: #333;
	background: $white;
}

.leaflet-control-layers-scrollbar {
	overflow-y: scroll;
	overflow-x: hidden;
	padding-inline-end: 5px;
}

.leaflet-control-layers-selector {
	margin-block-start: 2px;
	position: relative;
	inset-block-start: 1px;
}

.leaflet-control-layers-separator {
	height: 0;
	border-block-start: 1px solid #ddd;
	margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
	background-image: url(../plugins/leaflet/images/marker-icon.png);
}

.leaflet-control-attribution {
	padding: 0 5px;
	color: #333;

	a {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.leaflet-control-scale-line {
	padding: 0 5px;
	color: #333;
	border: 2px solid #777;
	border-block-start: none;
	line-height: 1.1;
	padding: 2px 5px 1px;
	font-size: 11px;
	white-space: nowrap;
	overflow: hidden;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background: $white;
	background: $white-5;

	&:not(:first-child) {
		border-block-start: 2px solid #777;
		border-block-end: none;
		margin-block-start: -2px;

		&:not(:last-child) {
			border-block-end: 2px solid #777;
		}
	}
}

.leaflet-popup {
	position: absolute;
	text-align: center;
	margin-block-end: 20px;
}

.leaflet-popup-content-wrapper {
	padding: 1px;
	text-align: start;
	border-radius: 12px;
	background: white;
	color: #333;
	box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-popup-content {
	margin: 13px 19px;
	line-height: 1.4;

	p {
		margin: 18px 0;
	}
}

.leaflet-popup-tip-container {
	width: 40px;
	height: 20px;
	position: absolute;
	inset-inline-start: 50%;
	margin-inline-start: -20px;
	overflow: hidden;
	pointer-events: none;
}

.leaflet-popup-tip {
	width: 17px;
	height: 17px;
	padding: 1px;
	margin: -10px auto 0;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	background: white;
	color: #333;
	box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-popup-scrolled {
	overflow: auto;
	border-block-end: 1px solid #ddd;
	border-block-start: 1px solid #ddd;
}

.leaflet-oldie {
	.leaflet-popup-content-wrapper {
		zoom: 1;
		border: 1px solid #999;
	}

	.leaflet-popup-tip {
		width: 24px;
		margin: 0 auto;
		-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
		filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
		border: 1px solid #999;
	}

	.leaflet-popup-tip-container {
		margin-block-start: -1px;
	}

	.leaflet-control-zoom {
		border: 1px solid #999;
	}

	.leaflet-control-layers {
		border: 1px solid #999;
	}
}

.leaflet-div-icon {
	background: $white;
	border: 1px solid #666;
}

.leaflet-tooltip {
	position: absolute;
	padding: 6px;
	background-color: $white;
	border: 1px solid $white;
	border-radius: 3px;
	color: #222;
	white-space: nowrap;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-clickable {
	cursor: pointer;
	pointer-events: auto;
}

.leaflet-tooltip-top {
	&:before {
		position: absolute;
		pointer-events: none;
		border: 6px solid transparent;
		background: transparent;
		content: "";
		inset-inline-start: 50%;
		margin-inline-start: -6px;
		inset-block-end: 0;
		margin-block-end: -12px;
		border-block-start-color: $white;
	}

	margin-block-start: -6px;
}

.leaflet-tooltip-bottom {
	&:before {
		position: absolute;
		pointer-events: none;
		border: 6px solid transparent;
		background: transparent;
		content: "";
		inset-inline-start: 50%;
		margin-inline-start: -6px;
		inset-block-start: 0;
		margin-block-start: -12px;
		margin-inline-start: -6px;
		border-block-end-color: $white;
	}

	margin-block-start: 6px;
}

.leaflet-tooltip-left {
	&:before {
		position: absolute;
		pointer-events: none;
		border: 6px solid transparent;
		background: transparent;
		content: "";
		inset-block-start: 50%;
		margin-block-start: -6px;
		inset-inline-end: 0;
		margin-inline-end: -12px;
		border-start-color: $white;
	}

	margin-inline-start: -6px;
}

.leaflet-tooltip-right {
	&:before {
		position: absolute;
		pointer-events: none;
		border: 6px solid transparent;
		background: transparent;
		content: "";
		inset-block-start: 50%;
		margin-block-start: -6px;
		inset-inline-start: 0;
		margin-inline-start: -12px;
		border-end-color: $white;
	}

	margin-inline-start: 6px;
}

/* ************** LEAFLET MAPS CLOSED ************** */
/* ************** JQUERY TRANSFER OPEN ************** */

/*?????radio???*/
/* + ??????,??????label??*/
/*?????radio???*/
/* + ??????,??????label??*/
/*
 *????
 */
/*
 * input placeholder style
 */
/*
 * webkit????????
 */
/** responsive **/
.checkbox-group {
    .checkbox-normal {
        display: none;

        + {
            label {
                &:before {
                    -webkit-border-radius: 0px;
                    -moz-border-radius: 0px;
                    border-radius: 3px;
                }
            }
        }

        &:checked {
            + {
                label {
                    &:before {
                        content: "\e93e";
                        font-family: "iconfont";
                        font-size: 9px;
                        text-align: center;
                        line-height: 13px;
                        margin: 0 auto;
                        text-align: center;
                        color: $white;
                    }
                }
            }
        }

        &:disabled {
            + {
                label {
                    &:before {
                        color: $white;
                        border: 1px solid #ccc;
                        background-color: #e7eaf3;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }

    display: inline-block;
    position: relative;

    label {
        display: inline-block;
        position: relative;
        padding-inline-start: 25px;
        cursor: pointer;
        font-size: 13px;
        line-height: 21px;

        &:before {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            position: absolute;
            inset-block-start: 3px;
            inset-inline-start: 0;
            border-radius: 3px;
            background: $white;
            border: 1px solid #e3e4e9;
        }
    }

    + {
        .checkbox-group {
            label {
                margin-inline-start: 15px;
            }
        }
    }

    .checkbox-indeterminate {
        + {
            label {
                &:before {
                    content: "\e95f";
                    font-family: "iconfont";
                    font-size: 14px;
                    text-align: center;
                    line-height: 15px;
                    color: $white;
                    -webkit-border-radius: 3px;
                    -moz-border-radius: 3px;
                    border-radius: 3px;
                }
            }
        }
    }
}

.clearfix {
    &:before {
        content: "";
        display: table;
        display: table;
        content: " ";
    }

    &:after {
        content: "";
        display: table;
        display: table;
        content: " ";
        clear: both;
    }

    zoom: 1;
}

.radio-group {
    label {
        &:before {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            position: absolute;
            inset-block-start: 3px;
            inset-inline-start: 0px;
            background: #fcfcfc;
            border: 1px solid #bbb;
        }
    }

    .radio-normal {
        + {
            label {
                &:before {
                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    border-radius: 10px;
                }
            }
        }

        &:checked {
            + {
                label {
                    &:before {
                        content: "\e6bf";
                        font-family: "iconfont";
                        color: $white;
                        font-size: 14px;
                        line-height: 15px;
                    }
                }
            }
        }

        &:disabled {
            + {
                label {
                    &:before {
                        color: $white;
                        border: 1px solid #ccc;
                        background: #e7eaf3;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}

.multi-select-active {
    &:after {
        content: "\e93e";
        font-family: "iconfont";
        margin-inline-start: 64%;
        font-weight: bold;
    }
}

.icon-single-add {
    font-size: 22px;
    position: absolute;
    margin-inline-start: -30px;
    margin-block-start: 7px;
}

.icon-double-add {
    font-size: 22px;
    position: absolute;
    margin-inline-start: -30px;
    margin-block-start: 7px;
}

.transfer-double {
    width: 100%;
    height: 100%;
    background-color: $white;
    inset-inline-start: 30%;
    position: inherit;
    z-index: 1000;
    border-radius: 0px;
}

.transfer-double-content-left {
    width: 46%;
    border: 1px solid #e3e4e9;
    border-radius: 5px;
    float: $float-left;
    overflow: hidden;
}

.transfer-double-content-middle {
    width: 35px;
    float: $float-left;
    margin-block-start: 12%;
    margin-block-end: 0;
    margin-inline-start: 22px;
    margin-inline-end: 24px;
    text-align: center;
    vertical-align: middle;
}

.transfer-double-content-right {
    display: inline-block;
    width: 46%;
    border: 1px solid #e3e4e9;
    border-radius: 5px;
}

.transfer-double-content-tabs {
    display: inline-block;
    width: 100%;
    border-block-end: 1px solid #e3e4e9;

    .tab-item-name.tab-active {
        background: #f0f0f2;
    }

    .tab-item-name {
        display: inline-block;
        line-height: 36px;
        margin-inline-start: 15%;
        width: 50%;
        cursor: pointer;
        margin: 0;
        font-weight: 500;
        text-align: center;
        padding: 3px;
    }
}

.transfer-double-content-param {
    display: inline-block;
    width: 100%;
    line-height: 36px;
    border-block-end: 1px solid #e7eaf3;
    text-align: center;
    padding: 3px;
    font-weight: 500;

    .param-item {
        display: inline-block;
        width: 40%;
        height: 36px;
        line-height: 36px;
    }
}

.vertical-separation-line {
    color: #eee;
    margin: 0 10px;
}

.transfer-double-list {
    display: none;
}

.tab-content-active {
    display: block;
}

.transfer-double-list-header {
    padding: 8px 8px 6px 8px;
    text-align: center;
}

.transfer-double-list-search-input {
    width: 100%;
    line-height: 24px;
    border: 1px solid #e3e4e9;
    padding-block-start: 5px;
    padding-inline-end: 0;
    padding-block-end: 4px;
    padding-inline-start: 8px;
    outline: 0;
}

.transfer-double-list-content {
    padding: 3px 3px;
}

.transfer-double-list-main {
    height: 210px;
    overflow-y: auto;

    .transfer-double-list-ul {
        list-style: none;
        padding-inline-start: 7px;
        margin: 0;

        .transfer-double-list-li {
            margin-block-start: 5px;

            input {
                margin-inline-end: 10px;
            }
        }
    }

    .transfer-double-group-list-ul {
        list-style: none;
        padding-inline-start: 7px;
        margin: 0;

        .transfer-double-group-list-li {
            margin-block-start: 5px;

            .transfer-double-group-list-li-ul {
                list-style: none;
                padding-inline-start: 20px;

                .transfer-double-group-list-li-ul-li {
                    margin-block-start: 5px;
                }
            }
        }
    }

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #f6f6f6;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background: #e7eaf3;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-corner {
        background: #f6f6f6;
    }
}

.transfer-double-selected-list-header {
    padding: 8px 8px 6px 8px;
    text-align: center;
}

.transfer-double-selected-list-search-input {
    width: 100%;
    line-height: 24px;
    color: #777ab2 !important;
    border: 1px solid #e3e4e9;
    padding-block-start: 5px;
    padding-inline-end: 0;
    padding-block-end: 4px;
    padding-inline-start: 8px;
    outline: 0;
}

.transfer-double-selected-list-content {
    padding: 3px 3px;
}

.transfer-double-selected-list-main {
    height: 210px;
    overflow-y: auto;

    .transfer-double-selected-list-ul {
        list-style: none;
        padding-inline-start: 7px;
        margin: 0;

        .transfer-double-selected-list-li {
            margin-block-start: 5px;

            .checkbox-group {
                width: 65%;
            }

            input[type="checkbox"] {
                margin-inline-end: 10px;
            }

            input[type="text"] {
                min-width: 40px;
                width: 40px;
                min-height: 24px;
                height: 24px;
            }
        }
    }

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #f6f6f6;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background: #e7eaf3;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-corner {
        background: #f6f6f6;
    }
}

.transfer-double-list-footer {
    border-block-start: 1px solid #e3e4e9;
    padding-inline-start: 0px;
    padding-block-start: 1px;

    span {
        margin-inline-start: 20px;
    }

    input {
        min-width: 40px;
        width: 40px;
        min-height: 24px;
        height: 24px;
        margin-inline-start: 75px;
    }

    .btn-setting {
        display: inline-block;
        margin-inline-start: 5px;
    }

    label {
        margin-inline-start: 10px;
        margin-block-start: 5px;
    }
}

.transfer-double-footer {
    text-align: right;
}

.btn-select-arrow {
    display: inline-block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 3px;
    color: #1a1630;
    background: #f0f0f2;
    border: 1px solid #e3e4e9;
    cursor: pointer;

    + {
        .btn-select-arrow {
            margin-block-start: 10px;
        }
    }
}

input {
    &::-webkit-input-placeholder {
        font-size: 12px;
        color: #bbb;
    }

    &:-moz-placeholder {
        font-size: 12px;
        color: #bbb;
    }

    &::-moz-placeholder {
        font-size: 12px;
        color: #bbb;
    }

    &:-ms-input-placeholder {
        font-size: 12px;
        color: #bbb;
    }
}

@media (max-width: 480px) {
    .transfer-double-content-left {
        width: 100%;
    }

    .transfer-double-content-right {
        width: 100%;
    }

    .transfer-double-content-middle {
        margin-block-start: 15px;
        margin-inline-end: 15px;
        margin-block-end: 15px;
        margin-inline-start: 50%;
    }
}

@media (min-width: 481px) and (max-width: 992px) {
    .transfer-double-content-left {
        width: 100%;
    }

    .transfer-double-content-right {
        width: 100%;
    }

    .transfer-double-content-middle {
        margin-block-start: 15px;
        margin-inline-end: 15px;
        margin-block-end: 15px;
        margin-inline-start: 50%;
    }

    .transfer {
        padding-inline-end: 0 !important;
    }
}

@media (min-width: 993px) and (max-width: 1366px) {
    .transfer-double-content-left {
        width: 42%;
    }

    .transfer-double-content-right {
        width: 42%;
    }
}

/* ************** JQUERY TRANSFER CLOSED ************** */
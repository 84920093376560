
/* ************** OWL CAROUSEL OPEN ************** */

/** * Owl Carousel v2.3.4 * Copyright 2013-2018 David Deutsch * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE */
/* * Owl Carousel - Core */
/* No Js */
/* * Owl Carousel - Animate Plugin */
@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* * Owl Carousel - Auto Height Plugin */
/* * Owl Carousel - Lazy Load Plugin */
/* * Owl Carousel - Video Plugin */
.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;

    .owl-stage {
        position: relative;
        -ms-touch-action: pan-Y;
        touch-action: manipulation;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;

        &:after {
            content: ".";
            display: block;
            clear: both;
            visibility: hidden;
            line-height: 0;
            height: 0;
        }
    }

    .owl-stage-outer {
        position: relative;
        overflow: hidden;
        -webkit-transform: translate3d(0px, 0px, 0px);
        transform: translate3d(0px, 0px, 0px);
    }

    .owl-wrapper {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
    }

    .owl-item {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        position: relative;
        min-height: 1px;
        float:$float-left;
        -webkit-backface-visibility: hidden;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;

        img {
            display: block;
        }

        .owl-lazy {
            opacity: 0;
            transition: opacity 400ms ease;

            &:not([src]) {
                max-height: 0;
            }
        }

        .owl-lazy[src^=""] {
            max-height: 0;
        }

        img.owl-lazy {
            transform-style: preserve-3d;
        }
    }

    .owl-nav.disabled {
        display: none;
    }

    .owl-dots.disabled {
        display: none;
    }

    .owl-nav {
        .owl-prev {
            cursor: pointer;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .owl-next {
            cursor: pointer;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        button.owl-prev {
            background: rgba(105, 99, 255, 0.3);
            opacity: 7;
            color: inherit;
            border: none;
            padding: 0 !important;
            font: inherit;
        }

        button.owl-next {
            background: rgba(105, 99, 255, 0.3);
            opacity: 7;
            color: inherit;
            border: none;
            padding: 0 !important;
            font: inherit;
        }
    }

    .owl-dot {
        cursor: pointer;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    button.owl-dot {
        background: rgba(105, 99, 255, 0.3);
        opacity: 7;
        color: inherit;
        border: none;
        padding: 0 !important;
        font: inherit;
    }

    .animated {
        animation-duration: 1000ms;
        animation-fill-mode: both;
    }

    .owl-animated-in {
        z-index: 0;
    }

    .owl-animated-out {
        z-index: 1;
    }

    .fadeOut {
        animation-name: fadeOut;
    }

    .owl-video-wrapper {
        position: relative;
        height: 100%;
        background: #000;
    }

    .owl-video-play-icon {
        position: absolute;
        height: 80px;
        width: 80px;
        inset-inline-start: 50%;
        inset-block-start: 50%;
        margin-inline-start: -40px;
        margin-block-start: -40px;
        cursor: pointer;
        z-index: 1;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: transform 100ms ease;

        &:hover {
            -ms-transform: scale(1.3, 1.3);
            transform: scale(1.3, 1.3);
        }
    }

    .owl-video-playing {
        .owl-video-tn {
            display: none;
        }

        .owl-video-play-icon {
            display: none;
        }
    }

    .owl-video-tn {
        opacity: 0;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        transition: opacity 400ms ease;
    }

    .owl-video-frame {
        position: relative;
        z-index: 1;
        height: 100%;
        width: 100%;
    }
}

.owl-carousel.owl-loaded {
    display: block;
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block;
}

.owl-carousel.owl-hidden {
    opacity: 0;
}

.owl-carousel.owl-refresh {
    .owl-item {
        visibility: hidden;
    }
}

.owl-carousel.owl-drag {
    .owl-item {
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab;
}

.owl-carousel.owl-rtl {
    direction: rtl;

    .owl-item {
        float:$float-right;
    }
}

.no-js {
    .owl-carousel {
        display: block;
    }
}

.owl-height {
    transition: height 500ms ease-in-out;
}

.owl-controls {
    margin-block-end: 30px;
    text-align: center;

    .owl-page {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        border: 4px solid #d9d6e0;
        background-size: cover;
        transition: all 0.3s ease-in-out 0s;
        display: inline-block;
        zoom: 1;

        &:nth-child(2) {
            background-size: cover;
        }

        &:nth-child(3) {
            background-size: cover;
        }
    }

    .owl-page.active {
        border-color: #ed5151;
        opacity: 1;

        &:hover {
            border-color: #ed5151;
            opacity: 1;
        }
    }
}

.owl-controls.clickable {
    .owl-page {
        &:hover {
            opacity: 0.5;
        }
    }
}

.testimonia {
    .post {
        display: block;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.7);
    }

    .title {
        font-size: 24px;
        font-weight: bold;
        color: $white;
        text-transform: capitalize;
        margin: 0;
        margin-block-end: 1px;
    }
}

.owl-nav {
    .owl-prev {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: -25px;
        inset-inline-end: -1.5em;
        margin-block-start: -1.65em;
    }

    button {
        display: block;
        font-size: 1.3rem !important;
        line-height: 2em;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        text-align: center;
        background: rgba(255, 255, 255, 0.5) !important;
        border: 1px solid #e8ebf3 !important;
        z-index: 99;
        box-shadow: 0 4px 15px #d7d7d7;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            inset-block-start: 0;
            inset-inline-start: 0;
            inset-inline-end: 0;
            inset-block-end: 0;
            background: #2098d1;
            -webkit-transform: scaleY(0);
            transform: scaleY(0);
            -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
    }

    .owl-next {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-end: -25px;
        margin-block-start: -1.65em;
    }
}

/* ************** OWL CAROUSEL CLOSED ************** */
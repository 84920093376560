
/* ************** GALLERY OPEN ************** */

@font-face {
	font-family: 'lg';
	src: url("../plugins/gallery/fonts/lg.eot?n1z373");
	src: url("../plugins/gallery/fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("../plugins/gallery/fonts/lg.woff?n1z373") format("woff"), url("../plugins/gallery/fonts/lg.ttf?n1z373") format("truetype"), url("../plugins/gallery/fonts/lg.svg?n1z373#lg") format("svg");
	font-weight: normal;
	font-style: normal;
}

.lg-icon {
	font-family: 'lg';
	speak-as: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.lg-actions {

	.lg-next,
	.lg-prev {
		background-color: $primary;
		border-radius: 2px;
		cursor: pointer;
		display: block;
		font-size: 22px;
		margin-block-start: -10px;
		padding: 8px 10px 9px;
		position: absolute;
		inset-block-start: 50%;
		z-index: 1080;
		color: $white-8;
	}

	.lg-next.disabled,
	.lg-prev.disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	.lg-next:hover,
	.lg-prev:hover {
		color: $white;
	}

	.lg-next {
		inset-inline-end: 20px;

		&:before {
			content: "\e095";
		}
	}

	.lg-prev {
		inset-inline-start: 20px;

		&:after {
			content: "\e094";
		}
	}
}

@-webkit-keyframes lg-right-end {
	0% {
		inset-inline-start: 0;
	}

	50% {
		inset-inline-start: -30px;
	}

	100% {
		inset-inline-start: 0;
	}
}

@-moz-keyframes lg-right-end {
	0% {
		inset-inline-start: 0;
	}

	50% {
		inset-inline-start: -30px;
	}

	100% {
		inset-inline-start: 0;
	}
}

@-ms-keyframes lg-right-end {
	0% {
		inset-inline-start: 0;
	}

	50% {
		inset-inline-start: -30px;
	}

	100% {
		inset-inline-start: 0;
	}
}

@keyframes lg-right-end {
	0% {
		inset-inline-start: 0;
	}

	50% {
		inset-inline-start: -30px;
	}

	100% {
		inset-inline-start: 0;
	}
}

@-webkit-keyframes lg-left-end {
	0% {
		inset-inline-start: 0;
	}

	50% {
		inset-inline-start: 30px;
	}

	100% {
		inset-inline-start: 0;
	}
}

@-moz-keyframes lg-left-end {
	0% {
		inset-inline-start: 0;
	}

	50% {
		inset-inline-start: 30px;
	}

	100% {
		inset-inline-start: 0;
	}
}

@-ms-keyframes lg-left-end {
	0% {
		inset-inline-start: 0;
	}

	50% {
		inset-inline-start: 30px;
	}

	100% {
		inset-inline-start: 0;
	}
}

@keyframes lg-left-end {
	0% {
		inset-inline-start: 0;
	}

	50% {
		inset-inline-start: 30px;
	}

	100% {
		inset-inline-start: 0;
	}
}

.lg-outer {
	&.lg-right-end .lg-object {
		-webkit-animation: lg-right-end 0.3s;
		-o-animation: lg-right-end 0.3s;
		animation: lg-right-end 0.3s;
		position: relative;
	}

	&.lg-left-end .lg-object {
		-webkit-animation: lg-left-end 0.3s;
		-o-animation: lg-left-end 0.3s;
		animation: lg-left-end 0.3s;
		position: relative;
	}
}

.lg-toolbar {
	z-index: 1082;
	inset-inline-start: 0;
	position: absolute;
	inset-block-start: 0;
	width: 100%;
	background-color: $primary;

	.lg-icon {
		cursor: pointer;
		float:$float-right;
		font-size: 24px;
		height: 47px;
		line-height: 27px;
		padding: 10px 0;
		text-align: center;
		width: 50px;
		text-decoration: none !important;
		outline: medium none;
		-webkit-transition: color 0.2s linear;
		-o-transition: color 0.2s linear;
		transition: color 0.2s linear;
		color: $white-8;

		&:hover {
			color: $white;
		}
	}

	.lg-close:after {
		content: "\e070";
	}

	.lg-download:after {
		content: "\e0f2";
	}
}

.lg-sub-html {
	background-color: $primary;
	inset-block-end: 0;
	color: #EEE;
	font-size: 16px;
	inset-inline-start: 0;
	padding: 10px 40px;
	position: fixed;
	inset-inline-end: 0;
	text-align: center;
	z-index: 1080;

	h4 {
		margin: 0;
		font-size: 16px;
		font-weight: 500;
	}

	p {
		font-size: 12px;
		margin: 5px 0 0;
	}
}

#lg-counter {
	display: inline-block;
	font-size: 16px;
	padding-inline-start: 20px;
	padding-block-start: 12px;
	vertical-align: middle;
	color: $white-8;
}

.lg-toolbar,
.lg-prev,
.lg-next {
	opacity: 1;
	-webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
	-moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
	-o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
	transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
}

.lg-hide-items {
	.lg-prev {
		opacity: 0;
		-webkit-transform: translate3d(-10px, 0, 0);
		transform: translate3d(-10px, 0, 0);
	}

	.lg-next {
		opacity: 0;
		-webkit-transform: translate3d(10px, 0, 0);
		transform: translate3d(10px, 0, 0);
	}

	.lg-toolbar {
		opacity: 0;
		-webkit-transform: translate3d(0, -10px, 0);
		transform: translate3d(0, -10px, 0);
	}
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom {
	.lg-object {
		-webkit-transform: scale3d(0.5, 0.5, 0.5);
		transform: scale3d(0.5, 0.5, 0.5);
		opacity: 0;
		-webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
		-moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
		-o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
		transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
		-webkit-transform-origin: 50% 50%;
		-moz-transform-origin: 50% 50%;
		-ms-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}

	.lg-item.lg-complete .lg-object {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
		opacity: 1;
	}
}

.lg-outer {
	.lg-thumb-outer {
		background-color: #0D0A0A;
		inset-block-end: 0;
		position: absolute;
		width: 100%;
		z-index: 1080;
		max-height: 350px;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
		-webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
		-moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
		-o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
		transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;

		&.lg-grab .lg-thumb-item {
			cursor: -webkit-grab;
			cursor: -moz-grab;
			cursor: -o-grab;
			cursor: -ms-grab;
			cursor: grab;
		}

		&.lg-grabbing .lg-thumb-item {
			cursor: move;
			cursor: -webkit-grabbing;
			cursor: -moz-grabbing;
			cursor: -o-grabbing;
			cursor: -ms-grabbing;
			cursor: grabbing;
		}

		&.lg-dragging .lg-thumb {
			-webkit-transition-duration: 0s !important;
			transition-duration: 0s !important;
		}
	}

	&.lg-thumb-open .lg-thumb-outer {
		-webkit-transform: translate3d(0, 0%, 0);
		transform: translate3d(0, 0%, 0);
	}

	.lg-thumb {
		padding: 10px 0;
		height: 100%;
		margin-block-end: -5px;
	}

	.lg-thumb-item {
		border-radius: 5px;
		cursor: pointer;
		float:$float-left;
		overflow: hidden;
		height: 100%;
		border: 2px solid $white;
		border-radius: 4px;
		margin-block-end: 5px;

		&.active,
		&:hover {
			border-color: #a90707;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&.lg-has-thumb .lg-item {
		padding-block-end: 120px;
	}

	&.lg-can-toggle .lg-item {
		padding-block-end: 0;
	}

	&.lg-pull-caption-up {
		.lg-sub-html {
			-webkit-transition: bottom 0.25s ease;
			-o-transition: bottom 0.25s ease;
			transition: bottom 0.25s ease;
		}

		&.lg-thumb-open .lg-sub-html {
			inset-block-end: 100px;
		}
	}

	.lg-toggle-thumb {
		background-color: #0D0A0A;
		border-radius: 2px 2px 0 0;
		cursor: pointer;
		font-size: 24px;
		height: 39px;
		line-height: 27px;
		padding: 5px 0;
		position: absolute;
		inset-inline-end: 20px;
		text-align: center;
		inset-block-start: -39px;
		width: 50px;

		&:after {
			content: "\e1ff";
		}

		&:hover {
			color: $white;
		}
	}

	.lg-video-cont {
		display: inline-block;
		vertical-align: middle;
		max-width: 1140px;
		max-height: 100%;
		width: 100%;
		padding: 0 5px;
	}

	.lg-video {
		width: 100%;
		height: 0;
		padding-block-end: 56.25%;
		overflow: hidden;
		position: relative;

		.lg-object {
			display: inline-block;
			position: absolute;
			inset-block-start: 0;
			inset-inline-start: 0;
			width: 100% !important;
			height: 100% !important;
		}

		.lg-video-play {
			width: 84px;
			height: 59px;
			position: absolute;
			inset-inline-start: 50%;
			inset-block-start: 50%;
			margin-inline-start: -42px;
			margin-block-start: -30px;
			z-index: 1080;
			cursor: pointer;
		}
	}

	.lg-has-vimeo {
		.lg-video-play {
			background: url("../img/vimeo-play.png") no-repeat scroll 0 0 transparent;
		}

		&:hover .lg-video-play {
			background: url("../img/vimeo-play.png") no-repeat scroll 0 -58px transparent;
		}
	}

	.lg-has-html5 {
		.lg-video-play {
			background: transparent url("../img/video-play.png") no-repeat scroll 0 0;
			height: 64px;
			margin-inline-start: -32px;
			margin-block-start: -32px;
			width: 64px;
			opacity: 0.8;
		}

		&:hover .lg-video-play {
			opacity: 1;
		}
	}

	.lg-has-youtube {
		.lg-video-play {
			background: url("../img/youtube-play.png") no-repeat scroll 0 0 transparent;
		}

		&:hover .lg-video-play {
			background: url("../img/youtube-play.png") no-repeat scroll 0 -60px transparent;
		}
	}

	.lg-video-object {
		width: 100% !important;
		height: 100% !important;
		position: absolute;
		inset-block-start: 0;
		inset-inline-start: 0;
	}

	.lg-has-video {
		.lg-video-object {
			visibility: hidden;
		}

		&.lg-video-playing {

			.lg-object,
			.lg-video-play {
				display: none;
			}

			.lg-video-object {
				visibility: visible;
			}
		}
	}
}

@media (min-width: 1025px) {
	.lg-outer .lg-thumb-item {
		-webkit-transition: border-color 0.25s ease;
		-o-transition: border-color 0.25s ease;
		transition: border-color 0.25s ease;
	}
}

.lg-progress-bar {
	background-color: #333;
	height: 5px;
	inset-inline-start: 0;
	position: absolute;
	inset-block-start: 0;
	width: 100%;
	z-index: 1083;
	opacity: 0;
	-webkit-transition: opacity 0.08s ease 0s;
	-moz-transition: opacity 0.08s ease 0s;
	-o-transition: opacity 0.08s ease 0s;
	transition: opacity 0.08s ease 0s;

	.lg-progress {
		background-color: #a90707;
		height: 5px;
		width: 0;
	}

	&.lg-start .lg-progress {
		width: 100%;
	}
}

.lg-show-autoplay .lg-progress-bar {
	opacity: 1;
}

.lg-autoplay-button:after {
	content: "\e01d";
}

.lg-show-autoplay .lg-autoplay-button:after {
	content: "\e01a";
}

.lg-outer {
	&.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable {

		.lg-img-wrap,
		.lg-image {
			-webkit-transition-duration: 0s;
			transition-duration: 0s;
		}
	}

	&.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
		-webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
		-moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
		-o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
		transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
	}

	&.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
		-webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
		-moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
		-o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
		transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
	}

	.lg-item.lg-complete.lg-zoomable {
		.lg-img-wrap {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			-webkit-backface-visibility: hidden;
			-moz-backface-visibility: hidden;
			backface-visibility: hidden;
		}

		.lg-image {
			-webkit-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
			-webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
			-moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
			-o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
			transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
			-webkit-transform-origin: 0 0;
			-moz-transform-origin: 0 0;
			-ms-transform-origin: 0 0;
			transform-origin: 0 0;
			-webkit-backface-visibility: hidden;
			-moz-backface-visibility: hidden;
			backface-visibility: hidden;
		}
	}
}

#lg-zoom-in:after {
	content: "\e311";
}

#lg-actual-size {
	font-size: 20px;

	&:after {
		content: "\e033";
	}
}

#lg-zoom-out {
	opacity: 0.5;
	pointer-events: none;

	&:after {
		content: "\e312";
	}
}

.lg-zoomed #lg-zoom-out {
	opacity: 1;
	pointer-events: auto;
}

.lg-outer {
	.lg-pager-outer {
		inset-block-end: 60px;
		inset-inline-start: 0;
		position: absolute;
		inset-inline-end: 0;
		text-align: center;
		z-index: 1080;
		height: 10px;

		&.lg-pager-hover .lg-pager-cont {
			overflow: visible;
		}
	}

	.lg-pager-cont {
		cursor: pointer;
		display: inline-block;
		overflow: hidden;
		position: relative;
		vertical-align: top;
		margin: 0 5px;

		&:hover .lg-pager-thumb-cont {
			opacity: 1;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}

		&.lg-pager-active .lg-pager {
			box-shadow: 0 0 0 2px white inset;
		}
	}

	.lg-pager-thumb-cont {
		background-color: $white;
		color: $white;
		inset-block-end: 100%;
		height: 83px;
		inset-inline-start: 0;
		margin-block-end: 20px;
		margin-inline-start: -60px;
		opacity: 0;
		padding: 5px;
		position: absolute;
		width: 120px;
		border-radius: 3px;
		-webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
		-moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
		-o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
		transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
		-webkit-transform: translate3d(0, 5px, 0);
		transform: translate3d(0, 5px, 0);

		img {
			width: 100%;
			height: 100%;
		}
	}

	.lg-pager {
		background-color: $white-5;
		border-radius: 50%;
		box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
		display: block;
		height: 12px;
		-webkit-transition: box-shadow 0.3s ease 0s;
		-o-transition: box-shadow 0.3s ease 0s;
		transition: box-shadow 0.3s ease 0s;
		width: 12px;

		&:hover,
		&:focus {
			box-shadow: 0 0 0 8px white inset;
		}
	}

	.lg-caret {
		border-inline-start: 10px solid transparent;
		border-inline-end: 10px solid transparent;
		border-block-start: 10px dashed;
		inset-block-end: -10px;
		display: inline-block;
		height: 0;
		inset-inline-start: 50%;
		margin-inline-start: -5px;
		position: absolute;
		vertical-align: middle;
		width: 0;
	}
}

.lg-fullscreen:after {
	content: "\e20c";
}

.lg-fullscreen-on .lg-fullscreen:after {
	content: "\e20d";
}

.lg-outer {
	#lg-dropdown-overlay {
		background-color: rgba(0, 0, 0, 0.25);
		inset-block-end: 0;
		cursor: default;
		inset-inline-start: 0;
		position: fixed;
		inset-inline-end: 0;
		inset-block-start: 0;
		z-index: 1081;
		opacity: 0;
		visibility: hidden;
		-webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
		-o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
		transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
	}

	&.lg-dropdown-active {

		.lg-dropdown,
		#lg-dropdown-overlay {
			-webkit-transition-delay: 0s;
			transition-delay: 0s;
			-moz-transform: translate3d(0, 0px, 0);
			-o-transform: translate3d(0, 0px, 0);
			-ms-transform: translate3d(0, 0px, 0);
			-webkit-transform: translate3d(0, 0px, 0);
			transform: translate3d(0, 0px, 0);
			opacity: 1;
			visibility: visible;
		}

		#lg-share {
			color: $white;
		}
	}

	.lg-dropdown {
		background-color: $white;
		border-radius: 2px;
		font-size: 14px;
		list-style-type: none;
		margin: 0;
		padding: 10px 0;
		position: absolute;
		inset-inline-end: 0;
		text-align: start;
		inset-block-start: 50px;
		opacity: 0;
		visibility: hidden;
		-moz-transform: translate3d(0, 5px, 0);
		-o-transform: translate3d(0, 5px, 0);
		-ms-transform: translate3d(0, 5px, 0);
		-webkit-transform: translate3d(0, 5px, 0);
		transform: translate3d(0, 5px, 0);
		-webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
		-moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
		-o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
		transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;

		&:after {
			content: "";
			display: block;
			height: 0;
			width: 0;
			position: absolute;
			border: 8px solid transparent;
			border-block-end-color: $white;
			inset-inline-end: 16px;
			inset-block-start: -16px;
		}

		>li {
			&:last-child {
				margin-block-end: 0px;
			}

			&:hover {

				a,
				.lg-icon {
					color: #333;
				}
			}
		}

		a {
			color: #333;
			display: block;
			white-space: pre;
			padding: 4px 12px;
			font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
			font-size: 12px;

			&:hover {
				background-color: rgba(0, 0, 0, 0.07);
			}
		}

		.lg-dropdown-text {
			display: inline-block;
			line-height: 1;
			margin-block-start: -3px;
			vertical-align: middle;
		}

		.lg-icon {
			color: #333;
			display: inline-block;
			float: none;
			font-size: 20px;
			height: auto;
			line-height: 1;
			margin-inline-end: 8px;
			padding: 0;
			vertical-align: middle;
			width: auto;
		}
	}

	#lg-share {
		position: relative;

		&:after {
			content: "\e80d";
		}
	}

	#lg-share-facebook .lg-icon {
		color: #3b5998;

		&:after {
			content: "\e901";
		}
	}

	#lg-share-twitter .lg-icon {
		color: #00aced;

		&:after {
			content: "\e904";
		}
	}

	#lg-share-googleplus .lg-icon {
		color: #dd4b39;

		&:after {
			content: "\e902";
		}
	}

	#lg-share-pinterest .lg-icon {
		color: #cb2027;

		&:after {
			content: "\e903";
		}
	}
}

.group {
	*zoom: 1;

	&:before {
		display: table;
		content: "";
		line-height: 0;
	}

	&:after {
		display: table;
		content: "";
		line-height: 0;
		clear: both;
	}
}

.lg-outer {
	width: 100%;
	height: 100%;
	position: fixed;
	inset-block-start: 0;
	inset-inline-start: 0;
	z-index: 9999999;
	opacity: 0;
	-webkit-transition: opacity 0.15s ease 0s;
	-o-transition: opacity 0.15s ease 0s;
	transition: opacity 0.15s ease 0s;

	* {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	&.lg-visible {
		opacity: 1;
	}

	&.lg-css3 {
		.lg-item {

			&.lg-prev-slide,
			&.lg-next-slide,
			&.lg-current {
				-webkit-transition-duration: inherit !important;
				transition-duration: inherit !important;
				-webkit-transition-timing-function: inherit !important;
				transition-timing-function: inherit !important;
			}
		}

		&.lg-dragging .lg-item {

			&.lg-prev-slide,
			&.lg-next-slide,
			&.lg-current {
				-webkit-transition-duration: 0s !important;
				transition-duration: 0s !important;
				opacity: 1;
			}
		}
	}

	&.lg-grab img.lg-object {
		cursor: -webkit-grab;
		cursor: -moz-grab;
		cursor: -o-grab;
		cursor: -ms-grab;
		cursor: grab;
	}

	&.lg-grabbing img.lg-object {
		cursor: move;
		cursor: -webkit-grabbing;
		cursor: -moz-grabbing;
		cursor: -o-grabbing;
		cursor: -ms-grabbing;
		cursor: grabbing;
	}

	.lg {
		height: 100%;
		width: 100%;
		position: relative;
		overflow: hidden;
		margin-inline-start: auto;
		margin-inline-end: auto;
		max-width: 100%;
		max-height: 100%;
	}

	.lg-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		inset-inline-start: 0;
		inset-block-start: 0;
		white-space: nowrap;
	}

	.lg-item {
		display: none !important;
	}

	&.lg-css3 {

		.lg-prev-slide,
		.lg-current,
		.lg-next-slide {
			display: inline-block !important;
		}
	}

	&.lg-css .lg-current {
		display: inline-block !important;
	}

	.lg-item,
	.lg-img-wrap {
		display: inline-block;
		text-align: center;
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.lg-item:before {
		content: "";
		display: inline-block;
		height: 50%;
		width: 1px;
		margin-inline-end: -1px;
	}

	.lg-img-wrap {
		&:before {
			content: "";
			display: inline-block;
			height: 50%;
			width: 1px;
			margin-inline-end: -1px;
		}

		position: absolute;
		padding: 0 5px;
		inset-inline-start: 0;
		inset-inline-end: 0;
		inset-block-start: 0;
		inset-block-end: 0;
	}

	.lg-item {
		&.lg-complete {
			background-image: none;
		}

		&.lg-current {
			z-index: 1060;
		}
	}

	.lg-image {
		display: inline-block;
		vertical-align: middle;
		max-width: 50%;
		max-height: 50%;
		width: auto !important;
		height: auto !important;
	}

	&.lg-show-after-load .lg-item {

		.lg-object,
		.lg-video-play {
			opacity: 0;
			-webkit-transition: opacity 0.15s ease 0s;
			-o-transition: opacity 0.15s ease 0s;
			transition: opacity 0.15s ease 0s;
		}

		&.lg-complete {

			.lg-object,
			.lg-video-play {
				opacity: 1;
			}
		}
	}

	.lg-empty-html,
	&.lg-hide-download #lg-download {
		display: none;
	}
}

.lg-backdrop {
	position: fixed;
	inset-block-start: 0;
	inset-inline-start: 0;
	inset-inline-end: 0;
	inset-block-end: 0;
	z-index: 9999999;
	background-color: $black-8;
	opacity: 0;
	-webkit-transition: opacity 0.15s ease 0s;
	-o-transition: opacity 0.15s ease 0s;
	transition: opacity 0.15s ease 0s;

	&.in {
		opacity: 1;
	}
}

.lg-css3 {
	&.lg-no-trans {

		.lg-prev-slide,
		.lg-next-slide,
		.lg-current {
			-webkit-transition: none 0s ease 0s !important;
			-moz-transition: none 0s ease 0s !important;
			-o-transition: none 0s ease 0s !important;
			transition: none 0s ease 0s !important;
		}
	}

	&.lg-use-css3 .lg-item,
	&.lg-use-left .lg-item {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	&.lg-fade .lg-item {
		opacity: 0;

		&.lg-current {
			opacity: 1;
		}

		&.lg-prev-slide,
		&.lg-next-slide,
		&.lg-current {
			-webkit-transition: opacity 0.1s ease 0s;
			-moz-transition: opacity 0.1s ease 0s;
			-o-transition: opacity 0.1s ease 0s;
			transition: opacity 0.1s ease 0s;
		}
	}

	&.lg-slide {
		&.lg-use-css3 .lg-item {
			opacity: 0;

			&.lg-prev-slide {
				-webkit-transform: translate3d(-100%, 0, 0);
				transform: translate3d(-100%, 0, 0);
			}

			&.lg-next-slide {
				-webkit-transform: translate3d(100%, 0, 0);
				transform: translate3d(100%, 0, 0);
			}

			&.lg-current {
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
				opacity: 1;
			}

			&.lg-prev-slide,
			&.lg-next-slide,
			&.lg-current {
				-webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				-moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				-o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
			}
		}

		&.lg-use-left .lg-item {
			opacity: 0;
			position: absolute;
			inset-inline-start: 0;

			&.lg-prev-slide {
				inset-inline-start: -100%;
			}

			&.lg-next-slide {
				inset-inline-start: 100%;
			}

			&.lg-current {
				inset-inline-start: 0;
				opacity: 1;
			}

			&.lg-prev-slide,
			&.lg-next-slide,
			&.lg-current {
				-webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				-moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				-o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
				transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
			}
		}
	}
}

.lg-img-wrap img {
	border-radius: 5px;
}

/* ************** GALLERY CLOSED ************** */

/* ************** PERFECT SCROLLBAR OPEN ************** */

.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  inset-block-end: 0px;
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  inset-inline-end: 0;
  position: absolute;
}

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > {
  .ps__rail-x, .ps__rail-y {
    opacity: 0;
  }
}

.ps--focus > {
  .ps__rail-x, .ps__rail-y {
    opacity: 0;
  }
}

.ps--scrolling-x > .ps__rail-x, .ps--scrolling-y > .ps__rail-y {
  opacity: 0;
}

.ps {
  .ps__rail-x:hover, .ps__rail-y:hover, .ps__rail-x:focus, .ps__rail-y:focus, .ps__rail-x.ps--clicking, .ps__rail-y.ps--clicking {
    background-color: transparent;
    opacity: 0.9;
  }
}

.ps__thumb-x {
  background-color: rgba(67, 87, 133, 0.09);
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  inset-block-end: 2px;
  position: absolute;
}

.ps__thumb-y {
  background-color: #dfe2eb;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 3px;
  inset-inline-end: 2px;
  position: absolute;
}

.ps__rail-x {
  &:hover > .ps__thumb-x, &:focus > .ps__thumb-x, &.ps--clicking .ps__thumb-x {
    background-color: rgba(67, 87, 133, 0.09);
    height: 11px;
  }
}

.ps__rail-y {
  &:hover > .ps__thumb-y, &:focus > .ps__thumb-y, &.ps--clicking .ps__thumb-y {
    width: 5px;
  }
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

/* ************** PERFECT SCROLLBAR CLOSED ************** */
@import "custom/fonts/fonts";


/* ###### Plugins ####### */
@import "variables";
@import "plugins/accordion/accordion";
@import "plugins/bootstrap-datepicker/bootstrap-datepicker.min";
@import "plugins/bootstrap-datepicker/bootstrap-datepicker";
@import "plugins/bootstrap-daterangepicker/daterangepicker";
@import "plugins/charts-c3/c3-chart";
@import "plugins/cookies/accept-cookies";
@import "plugins/cookies/cookies";
@import "plugins/datatables/buttons.bootstrap5.min";
@import "plugins/datatables/dataTables.bootstrap5";
@import "plugins/datatables/responsive.bootstrap5.min";
@import "plugins/date-picker/date-picker";
@import "plugins/dragula/dragula";
@import "plugins/fancyuploder/fancy_fileupload";
@import "plugins/fileupload/fileupload";
@import "plugins/formwizard/smart_wizard";
@import "plugins/formwizard/smart_wizard_theme_dots";
@import "plugins/forn-wizard/forn-wizard";
@import "plugins/fullcalendar/fullcalendar";
@import "plugins/gallery/gallery";
@import "plugins/images-comparsion/twentytwenty";
@import "plugins/img-crop/cropme";
@import "plugins/ion-rangeslider/ion.rangeSlider";
@import "plugins/ion-rangeslider/ion.rangeSlider.skinFlat";
@import "plugins/jquery-countdown/jquery.countdown";
@import "plugins/jQuerytransfer/icon_font";
@import "plugins/jQuerytransfer/jquery.transfer";
@import "plugins/leaflet/leaflet";
@import "plugins/lightslider/lightslider.min";
@import "plugins/morris/morris";
@import "plugins/multi/multi.min";
@import "plugins/multipleselect/multiple-select";
@import "plugins/notify/jquery.growl";
@import "plugins/notify/notifIt";
@import "plugins/owl-carousel/owl-carousel";
@import "plugins/p-scrollbar/p-scrollbar";
@import "plugins/quill/quill.bubble";
@import "plugins/quill/quill.snow";
@import "plugins/select2/select2";
@import "plugins/sumoselect/sumoselect";
@import "plugins/sweet-alert/sweetalert";
@import "plugins/tabs/style";
@import "plugins/tagify-master/tagify";
@import "plugins/telephoneinput/telephoneinput";
@import "plugins/time-picker/jquery.timepicker";
@import "plugins/timeline/d";
@import "plugins/treeview/treeview";
@import "plugins/wysiwyag/richtext";



/* ######  Plugins ###### */
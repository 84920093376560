
/* ************** IMAGE COMPARISION OPEN ************** */

.twentytwenty-horizontal {
	.twentytwenty-handle {
		&:before {
			content: " ";
			display: block;
			background: white;
			position: absolute;
			z-index: 30;
			-webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
			-moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
			box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
			width: 2px;
			height: 9999px;
			inset-inline-start: 50%;
			margin-inline-start: -1.5px;
			inset-block-end: 50%;
			margin-block-end: 22px;
			-webkit-box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
			-moz-box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
			box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
		}

		&:after {
			content: " ";
			display: block;
			background: white;
			position: absolute;
			z-index: 30;
			-webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
			-moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
			box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
			width: 2px;
			height: 9999px;
			inset-inline-start: 50%;
			margin-inline-start: -1.5px;
			inset-block-start: 50%;
			margin-block-start: 22px;
			-webkit-box-shadow: 0 -3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
			-moz-box-shadow: 0 -3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
			box-shadow: 0 -3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
		}
	}

	.twentytwenty-before-label {
		&:before {
			inset-block-start: 50%;
			margin-block-start: -19px;
			inset-inline-start: 10px;
		}
	}

	.twentytwenty-after-label {
		&:before {
			inset-block-start: 50%;
			margin-block-start: -19px;
			inset-inline-end: 10px;
		}
	}
}

.twentytwenty-vertical {
	.twentytwenty-handle {
		&:before {
			content: " ";
			display: block;
			background: white;
			position: absolute;
			z-index: 30;
			-webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
			-moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
			box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
			width: 9999px;
			height: 3px;
			inset-block-start: 50%;
			margin-block-start: -1.5px;
			inset-inline-start: 50%;
			margin-inline-start: 22px;
			-webkit-box-shadow: 3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
			-moz-box-shadow: 3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
			box-shadow: 3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
		}

		&:after {
			content: " ";
			display: block;
			background: white;
			position: absolute;
			z-index: 30;
			-webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
			-moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
			box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
			width: 9999px;
			height: 3px;
			inset-block-start: 50%;
			margin-block-start: -1.5px;
			inset-inline-end: 50%;
			margin-inline-end: 22px;
			-webkit-box-shadow: -3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
			-moz-box-shadow: -3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
			box-shadow: -3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
		}
	}

	.twentytwenty-before-label {
		&:before {
			inset-inline-start: 50%;
			margin-inline-start: -45px;
			text-align: center;
			width: 90px;
			inset-block-start: 10px;
		}
	}

	.twentytwenty-after-label {
		&:before {
			inset-inline-start: 50%;
			margin-inline-start: -45px;
			text-align: center;
			width: 90px;
			inset-block-end: 10px;
		}
	}
}

.twentytwenty-before-label {
	position: absolute;
	inset-block-start: 0;
	width: 100%;
	height: 100%;
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-property: opacity;
	-moz-transition-property: opacity;
	transition-property: opacity;
	opacity: 0;

	&:before {
		color: white;
		font-size: 13px;
		letter-spacing: 0.1em;
		position: absolute;
		background: $white-2;
		line-height: 38px;
		padding: 0 20px;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
		content: attr(data-content);
	}
}

.twentytwenty-after-label {
	position: absolute;
	inset-block-start: 0;
	width: 100%;
	height: 100%;
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-property: opacity;
	-moz-transition-property: opacity;
	transition-property: opacity;
	opacity: 0;

	&:before {
		color: white;
		font-size: 13px;
		letter-spacing: 0.1em;
		position: absolute;
		background: $white-2;
		line-height: 38px;
		padding: 0 20px;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
		content: attr(data-content);
	}
}

.twentytwenty-overlay {
	position: absolute;
	inset-block-start: 0;
	width: 100%;
	height: 100%;
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-property: background;
	-moz-transition-property: background;
	transition-property: background;
	background: rgba(0, 0, 0, 0);
	z-index: 25;

	&:hover {
		background: rgba(0, 0, 0, 0.5);

		.twentytwenty-after-label {
			opacity: 1;
		}

		.twentytwenty-before-label {
			opacity: 1;
		}
	}
}

.twentytwenty-left-arrow {
	width: 0;
	height: 0;
	border: 6px inset transparent;
	position: absolute;
	inset-block-start: 50%;
	margin-block-start: -6px;
	border-inline-end: 10px solid white;
	inset-inline-start: 50%;
	margin-inline-start: -17px;
}

.twentytwenty-right-arrow {
	width: 0;
	height: 0;
	border: 6px inset transparent;
	position: absolute;
	inset-block-start: 50%;
	margin-block-start: -6px;
	border-inline-start: 10px solid white;
	inset-inline-end: 50%;
	margin-inline-end: -17px;
}

.twentytwenty-up-arrow {
	width: 0;
	height: 0;
	border: 6px inset transparent;
	position: absolute;
	inset-inline-start: 50%;
	margin-inline-start: -6px;
	border-block-end: 6px solid white;
	inset-block-start: 50%;
	margin-block-start: -17px;
}

.twentytwenty-down-arrow {
	width: 0;
	height: 0;
	border: 6px inset transparent;
	position: absolute;
	inset-inline-start: 50%;
	margin-inline-start: -6px;
	border-block-start: 6px solid white;
	inset-block-end: 50%;
	margin-block-end: -17px;
}

.twentytwenty-container {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	z-index: 0;
	overflow: hidden;
	position: relative;
	-webkit-user-select: none;
	user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	img {
		max-width: 100%;
		position: absolute;
		inset-block-start: 0;
		display: block;
	}

	* {
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
	}
}

.twentytwenty-container.active {
	.twentytwenty-overlay {
		background: rgba(0, 0, 0, 0);

		.twentytwenty-before-label {
			opacity: 0;
		}

		.twentytwenty-after-label {
			opacity: 0;
		}
	}

	&:hover.twentytwenty-overlay {
		background: rgba(0, 0, 0, 0);

		.twentytwenty-before-label {
			opacity: 0;
		}

		.twentytwenty-after-label {
			opacity: 0;
		}
	}
}

.twentytwenty-before {
	z-index: 20;
}

.twentytwenty-after {
	z-index: 10;
}

.twentytwenty-handle {
	height: 38px;
	width: 38px;
	position: absolute;
	inset-inline-start: 50%;
	inset-block-start: 50%;
	margin-inline-start: -22px;
	margin-block-start: -22px;
	border: 2px solid white;
	-webkit-border-radius: 1000px;
	-moz-border-radius: 1000px;
	border-radius: 1000px;
	-webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
	-moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
	box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
	z-index: 40;
	cursor: pointer;
	background: rgba(0, 0, 0, 0.6);
}

/* ************** IMAGE COMPARISION CLOSED ************** */
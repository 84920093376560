
/* ************** JQUERY COUNTDOWN OPEN ************** */

/* jQuery Countdown styles 2.0.0. */
#launch_date {
	margin: 0px auto 0px auto;
}

.countdown-rtl {
	direction: rtl;
}

.countdown-holding {
	span {
		color: #888;
	}
}

.countdown-row {
	clear: both;
	width: 100%;
	padding: 0px 2px;
	text-align: center;
}

.countdown {
	li {
		display: inline-block;
		font-size: 1.83rem;
		font-weight: bold;
		width: 88px;
		height: 88px;
		border-radius: 0px;
		line-height: 22px;
		padding: 20px;
		text-align: center;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		z-index: 1;
		box-shadow: 0 8px 18px 0 rgba(1, 10, 25, 0.3);
		color: $white;
		border-radius: 7px;
		background: $black-2;
	}

	.time {
		font-size: 13px;
		padding-block-start: 2px;
		font-weight: 300;
	}
}

@media (max-width:991px) {
	.countdown {
		li {
			margin: 20px 10px 10px 10px;
		}
	}

	.is-countdown {
		margin-block-start: 20px;
	}
}

@media (min-width:992px) {
	.countdown {
		li {
			margin: 10px 10px 5px 10px;
		}
	}
}

/* ************** JQUERY COUNTDOWN CLOSED ************** */
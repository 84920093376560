
/* ************** RICHTEXT OPEN ************** */

.richText {
	position: relative;
	width: 100%;

	.richText-form {
		font-family: Calibri, Verdana, Helvetica, sans-serif;

		label {
			display: block;
			padding: 14px 0px;
		}

		input[type="text"] {
			padding: 10px 15px;
			border: #ecf0fa solid 1px;
			min-width: 200px;
			width: 100%;
			border-radius: 5px;
			outline: 0;
		}

		input[type="file"] {
			padding: 10px 15px;
			border: #ecf0fa solid 1px;
			min-width: 200px;
			width: 100%;
			border-radius: 5px;
			outline: 0;
		}

		input[type="number"] {
			padding: 10px 15px;
			border: #ecf0fa solid 1px;
			min-width: 200px;
			width: 100%;
			border-radius: 5px;
			outline: 0;
		}

		select {
			padding: 10px 15px;
			border: #ecf0fa solid 1px;
			min-width: 200px;
			width: 100%;
			border-radius: 5px;
			outline: 0;
			cursor: pointer;
		}

		button {
			margin: 10px 0;
			padding: 0.375rem 0.75rem;
			background-color: #6259ca;
			border: none;
			color: #FAFAFA;
			cursor: pointer;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-radius: 5px;
		}
	}

	label {
		margin-block-end: 0px;
	}

	.richText-toolbar {
		min-height: 20px;

		ul {
			margin-block-end: 0;

			li {
				float:$float-left;
				display: block;
				list-style: none;

				a {
					display: block;
					padding: 10px 15px;
					cursor: pointer;
					-webkit-transition: background-color 0.4s;
					-moz-transition: background-color 0.4s;
					transition: background-color 0.4s;

					.fa {
						pointer-events: none;
					}

					.fas {
						pointer-events: none;
					}

					.far {
						pointer-events: none;
					}

					svg {
						pointer-events: none;
					}

					.richText-dropdown-outer {
						display: none;
						position: absolute;
						inset-block-start: 0;
						inset-inline-start: 0;
						inset-inline-end: 0;
						inset-block-end: 0;
						background-color: rgba(0, 0, 0, 0.3);
						cursor: default;

						.richText-dropdown {
							position: relative;
							display: block;
							margin: 3% auto 0 auto;
							background-color: $white;
							border: #ecf0fa solid 1px;
							z-index: 1;
							min-width: 100px;
							width: 300px;
							max-width: 90%;
							border-radius: 5px;
							padding-block-start: 22px;
							-webkit-box-shadow: 0px 16px 18px rgba(6, 10, 48, .1);
							-moz-box-shadow: 0px 16px 18px rgba(6, 10, 48, .1);
							box-shadow: 0px 16px 18px rgba(6, 10, 48, .1);

							.richText-dropdown-close {
								position: absolute;
								inset-block-start: 8px;
								inset-inline-end: 8px;
								color: #0d0c22;
								cursor: pointer;
								font-size: 16px;
								text-align: center;
								width: 25px;
								border-radius: 5px;
							}
						}

						ul.richText-dropdown {
							list-style: none;

							li {
								display: block;
								float: none;
								font-family: Calibri, Verdana, Helvetica, sans-serif;

								a {
									display: block;
									padding: 10px 15px;
									border-block-end: #EFEFEF solid 1px;

									&:hover {
										background-color: #FFFFFF;
									}
								}
							}

							li.inline {
								margin: 10px 6px;
								float:$float-right;

								a {
									display: block;
									padding: 0;
									margin: 0;
									border: none;
									-webkit-border-radius: 50%;
									-moz-border-radius: 50%;
									border-radius: 50%;
									-webkit-box-shadow: 0 0 10px 0 #999;
									-moz-box-shadow: 0 0 10px 0 #999;
									box-shadow: 0 0 10px 0 #999;

									span {
										display: block;
										height: 30px;
										width: 30px;
										-webkit-border-radius: 50%;
										-moz-border-radius: 50%;
										border-radius: 50%;
									}
								}
							}
						}

						div.richText-dropdown {
							padding: 10px 15px;
						}
					}

					&:hover {
						background-color: #FFFFFF;
					}
				}

				&:not([data-disable="true"]).is-selected {
					.richText-dropdown-outer {
						display: block;
					}
				}
			}

			li[data-disable="true"] {
				opacity: 0.1;

				a {
					cursor: default;
				}
			}

			&:after {
				display: block;
				content: "";
				clear: both;
			}
		}

		&:last-child {
			font-size: 12px;
		}

		&:after {
			display: block;
			clear: both;
			content: "";
		}
	}

	.richText-editor {
		padding: 20px;
		background-color: #FFFFFF;
		border-inline-start: #FFFFFF solid 2px;
		font-family: Calibri, Verdana, Helvetica, sans-serif;
		height: 300px;
		outline: none;
		overflow-y: scroll;
		overflow-x: auto;

		ul {
			margin: 10px 25px;
		}

		ol {
			margin: 10px 25px;
		}

		table {
			margin: 10px 0;
			border-spacing: 0;
			width: 100%;

			td {
				padding: 10px;
				border: #EFEFEF solid 1px;
			}

			th {
				padding: 10px;
				border: #EFEFEF solid 1px;
			}
		}

		&:focus {
			border-inline-start: #6259ca solid 2px;
		}
	}

	.richText-initial {
		margin-block-end: -4px;
		padding: 10px;
		background-color: #282828;
		border: none;
		color: #33FF33;
		font-family: Monospace, Calibri, Verdana, Helvetica, sans-serif;
		max-width: 100%;
		min-width: 100%;
		width: 100%;
		min-height: 400px;
		height: 400px;
	}

	.richText-help {
		float:$float-right;
		display: block;
		padding: 10px 15px;
		cursor: pointer;
	}

	.richText-undo {
		float:$float-right;
		display: block;
		padding: 10px 15px;
		border-inline-end: #EFEFEF solid 1px;
		cursor: pointer;
	}

	.richText-redo {
		float:$float-right;
		display: block;
		padding: 10px 15px;
		border-inline-end: #EFEFEF solid 1px;
		cursor: pointer;
	}

	.richText-undo.is-disabled {
		opacity: 0.4;
	}

	.richText-redo.is-disabled {
		opacity: 0.4;
	}

	.richText-help-popup {
		a {
			color: #3498db;
			text-decoration: underline;
		}

		hr {
			margin: 10px auto 5px auto;
			border: none;
			border-block-start: #EFEFEF solid 1px;
		}
	}

	.richText-list.list-rightclick {
		position: absolute;
		background-color: #FAFAFA;
		border-inline-end: #EFEFEF solid 1px;
		border-block-end: #EFEFEF solid 1px;

		li {
			padding: 5px 7px;
			cursor: pointer;
			list-style: none;
		}
	}
}

/* ************** RICHTEXT CLOSED ************** */

/* ************** MORRIS CHARTS OPEN ************** */

.morris-hover {
	position: absolute;
	z-index: 10;
}

.morris-hover.morris-default-style {
	border-radius: 10px;
	padding: 6px;
	color: #666;
	background: rgba(255, 255, 255, 0.8);
	border: solid 1px rgba(230, 230, 230, 0.1);
	font-family: sans-serif;
	font-size: 12px;
	text-align: center;

	.morris-hover-row-label {
		font-weight: bold;
		margin: 0.25em 0;
	}

	.morris-hover-point {
		white-space: nowrap;
		margin: 0.1em 0;
	}
}

#morrisDonut1 {
	height: 210px;

	tspan {
		font-weight: 600;
	}

	text {
		font-size: 14px !important;
		font-weight: 600 !important;
		fill: #53545e;
	}
}

#morrisDonut2 {
	height: 210px;

	tspan {
		font-weight: 600;
	}

	text {
		font-size: 14px !important;
		font-weight: 600 !important;
		fill: #53545e;
	}
}

#morrisDonut3 {
	height: 210px;

	tspan {
		font-weight: 600;
	}

	text {
		font-size: 14px !important;
		font-weight: 600 !important;
		fill: #53545e;
	}
}

#morrisDonut4 {
	height: 210px;

	tspan {
		font-weight: 600;
	}

	text {
		font-size: 14px !important;
		font-weight: 600 !important;
		fill: #53545e;
	}
}

/* ************** MORRIS CHARTS CLOSED ************** */
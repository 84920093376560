
/* ************** BOOTSTRAP DATEPICKER OPEN ************** */

.datepicker {
	padding: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	direction: ltr;
	table {
		margin: 0;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		tr {
			td {
				&.highlighted {
					background: #d9edf7;
					border-radius: 0;
				}
				&.today {
					&:hover {
						&:hover {
							color: #000;
						}
					}
					&.active {
						&:hover {
							color: $white;
						}
					}
				}
				span {
					display: block;
					width: 23%;
					height: 54px;
					line-height: 54px;
					float:$float-left;
					margin: 1%;
					cursor: pointer;
					-webkit-border-radius: 4px;
					-moz-border-radius: 4px;
					border-radius: 4px;
				}
			}
		}
	}
	.datepicker-switch {
		width: 145px;
	}
	.cw {
		font-size: 10px;
		width: 12px;
		padding: 0 2px 0 5px;
		vertical-align: middle;
	}
}
.datepicker-inline {
	width: 220px;
}
.datepicker-rtl {
	direction: rtl;
	&.dropdown-menu {
		inset-inline-start: auto;
	}
	table {
		tr {
			td {
				span {
					float:$float-right;
				}
			}
		}
	}
}
.datepicker-dropdown {
	inset-block-start: 0;
	inset-inline-start: 0;
	&:before {
		content: '';
		display: inline-block;
		border-inline-start: 7px solid transparent;
		border-inline-end: 7px solid transparent;
		border-block-end: 7px solid #999;
		border-block-start: 0;
		border-block-end-color: rgba(0,0,0,.2);
		position: absolute;
	}
	&:after {
		content: '';
		display: inline-block;
		border-inline-start: 6px solid transparent;
		border-inline-end: 6px solid transparent;
		border-block-end: 6px solid $white;
		border-block-start: 0;
		position: absolute;
	}
	&.datepicker-orient-left {
		&:before {
			inset-inline-start: 6px;
		}
		&:after {
			inset-inline-start: 7px;
		}
	}
	&.datepicker-orient-right {
		&:before {
			inset-inline-end: 6px;
		}
		&:after {
			inset-inline-end: 7px;
		}
	}
	&.datepicker-orient-bottom {
		&:before {
			inset-block-start: -7px;
		}
		&:after {
			inset-block-start: -6px;
		}
	}
	&.datepicker-orient-top {
		&:before {
			inset-block-end: -7px;
			border-block-end: 0;
			border-block-start: 7px solid #999;
		}
		&:after {
			inset-block-end: -6px;
			border-block-end: 0;
			border-block-start: 6px solid $white;
		}
	}
}
.datepicker td,.datepicker th {
	text-align: center;
	width: 20px;
	height: 20px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border: none;
}
.table-striped .datepicker table tr td,.table-striped .datepicker table tr th {
	background-color: transparent;
}
.datepicker table tr td.day.focused,.datepicker table tr td.day:hover {
	background: #eee;
	cursor: pointer;
}
.datepicker table tr td.new,.datepicker table tr td.old {
	color: #999;
}
.datepicker table tr td.disabled,.datepicker table tr td.disabled:hover {
	background: 0 0;
	color: #999;
	cursor: default;
}
.datepicker table tr td.today,.datepicker table tr td.today.disabled,.datepicker table tr td.today.disabled:hover,.datepicker table tr td.today:hover {
	background-color: #fde19a;
	background-image: -moz-linear-gradient(to bottom,#fdd49a,#fdf59a);
	background-image: -ms-linear-gradient(to bottom,#fdd49a,#fdf59a);
	background-image: -webkit-gradient(linear,0 0,0 100%,from(#fdd49a),to(#fdf59a));
	background-image: -webkit-linear-gradient(to bottom,#fdd49a,#fdf59a);
	background-image: -o-linear-gradient(to bottom,#fdd49a,#fdf59a);
	background-image: linear-gradient(to bottom,#fdd49a,#fdf59a);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
	border-color: #fdf59a #fdf59a #fbed50;
	border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: #000;
}
.datepicker table tr td.today.active,.datepicker table tr td.today.disabled,.datepicker table tr td.today.disabled.active,.datepicker table tr td.today.disabled.disabled,.datepicker table tr td.today.disabled:active,.datepicker table tr td.today.disabled:hover,.datepicker table tr td.today.disabled:hover.active,.datepicker table tr td.today.disabled:hover.disabled,.datepicker table tr td.today.disabled:hover:active,.datepicker table tr td.today.disabled:hover:hover,.datepicker table tr td.today.disabled:hover[disabled],.datepicker table tr td.today.disabled[disabled],.datepicker table tr td.today:active,.datepicker table tr td.today:hover,.datepicker table tr td.today:hover.active,.datepicker table tr td.today:hover.disabled,.datepicker table tr td.today:hover:active,.datepicker table tr td.today:hover:hover,.datepicker table tr td.today:hover[disabled],.datepicker table tr td.today[disabled] {
	background-color: #fdf59a;
}
.datepicker table tr td.today.active,.datepicker table tr td.today.disabled.active,.datepicker table tr td.today.disabled:active,.datepicker table tr td.today.disabled:hover.active,.datepicker table tr td.today.disabled:hover:active,.datepicker table tr td.today:active,.datepicker table tr td.today:hover.active,.datepicker table tr td.today:hover:active {
	background-color: #fbf069;
}
.datepicker table tr td.range,.datepicker table tr td.range.disabled,.datepicker table tr td.range.disabled:hover,.datepicker table tr td.range:hover {
	background: #eee;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}
.datepicker table tr td.range.today,.datepicker table tr td.range.today.disabled,.datepicker table tr td.range.today.disabled:hover,.datepicker table tr td.range.today:hover {
	background-color: #f3d17a;
	background-image: -moz-linear-gradient(to bottom,#f3c17a,#f3e97a);
	background-image: -ms-linear-gradient(to bottom,#f3c17a,#f3e97a);
	background-image: -webkit-gradient(linear,0 0,0 100%,from(#f3c17a),to(#f3e97a));
	background-image: -webkit-linear-gradient(to bottom,#f3c17a,#f3e97a);
	background-image: -o-linear-gradient(to bottom,#f3c17a,#f3e97a);
	background-image: linear-gradient(to bottom,#f3c17a,#f3e97a);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
	border-color: #f3e97a #f3e97a #edde34;
	border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}
.datepicker table tr td.range.today.active,.datepicker table tr td.range.today.disabled,.datepicker table tr td.range.today.disabled.active,.datepicker table tr td.range.today.disabled.disabled,.datepicker table tr td.range.today.disabled:active,.datepicker table tr td.range.today.disabled:hover,.datepicker table tr td.range.today.disabled:hover.active,.datepicker table tr td.range.today.disabled:hover.disabled,.datepicker table tr td.range.today.disabled:hover:active,.datepicker table tr td.range.today.disabled:hover:hover,.datepicker table tr td.range.today.disabled:hover[disabled],.datepicker table tr td.range.today.disabled[disabled],.datepicker table tr td.range.today:active,.datepicker table tr td.range.today:hover,.datepicker table tr td.range.today:hover.active,.datepicker table tr td.range.today:hover.disabled,.datepicker table tr td.range.today:hover:active,.datepicker table tr td.range.today:hover:hover,.datepicker table tr td.range.today:hover[disabled],.datepicker table tr td.range.today[disabled] {
	background-color: #f3e97a;
}
.datepicker table tr td.range.today.active,.datepicker table tr td.range.today.disabled.active,.datepicker table tr td.range.today.disabled:active,.datepicker table tr td.range.today.disabled:hover.active,.datepicker table tr td.range.today.disabled:hover:active,.datepicker table tr td.range.today:active,.datepicker table tr td.range.today:hover.active,.datepicker table tr td.range.today:hover:active {
	background-color: #efe24b;
}
.datepicker table tr td.selected,.datepicker table tr td.selected.disabled,.datepicker table tr td.selected.disabled:hover,.datepicker table tr td.selected:hover {
	background-color: #9e9e9e;
	background-image: -moz-linear-gradient(to bottom,#b3b3b3,grey);
	background-image: -ms-linear-gradient(to bottom,#b3b3b3,grey);
	background-image: -webkit-gradient(linear,0 0,0 100%,from(#b3b3b3),to(grey));
	background-image: -webkit-linear-gradient(to bottom,#b3b3b3,grey);
	background-image: -o-linear-gradient(to bottom,#b3b3b3,grey);
	background-image: linear-gradient(to bottom,#b3b3b3,grey);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
	border-color: grey grey #595959;
	border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: $white;
	text-shadow: 0 -1px 0 rgba(0,0,0,.25);
}
.datepicker table tr td.selected.active,.datepicker table tr td.selected.disabled,.datepicker table tr td.selected.disabled.active,.datepicker table tr td.selected.disabled.disabled,.datepicker table tr td.selected.disabled:active,.datepicker table tr td.selected.disabled:hover,.datepicker table tr td.selected.disabled:hover.active,.datepicker table tr td.selected.disabled:hover.disabled,.datepicker table tr td.selected.disabled:hover:active,.datepicker table tr td.selected.disabled:hover:hover,.datepicker table tr td.selected.disabled:hover[disabled],.datepicker table tr td.selected.disabled[disabled],.datepicker table tr td.selected:active,.datepicker table tr td.selected:hover,.datepicker table tr td.selected:hover.active,.datepicker table tr td.selected:hover.disabled,.datepicker table tr td.selected:hover:active,.datepicker table tr td.selected:hover:hover,.datepicker table tr td.selected:hover[disabled],.datepicker table tr td.selected[disabled] {
	background-color: grey;
}
.datepicker table tr td.selected.active,.datepicker table tr td.selected.disabled.active,.datepicker table tr td.selected.disabled:active,.datepicker table tr td.selected.disabled:hover.active,.datepicker table tr td.selected.disabled:hover:active,.datepicker table tr td.selected:active,.datepicker table tr td.selected:hover.active,.datepicker table tr td.selected:hover:active {
	background-color: #666;
}
.datepicker table tr td.active,.datepicker table tr td.active.disabled,.datepicker table tr td.active.disabled:hover,.datepicker table tr td.active:hover {
	background-color: #006dcc;
	background-image: -moz-linear-gradient(to bottom,#08c,#04c);
	background-image: -ms-linear-gradient(to bottom,#08c,#04c);
	background-image: -webkit-gradient(linear,0 0,0 100%,from(#08c),to(#04c));
	background-image: -webkit-linear-gradient(to bottom,#08c,#04c);
	background-image: -o-linear-gradient(to bottom,#08c,#04c);
	background-image: linear-gradient(to bottom,#08c,#04c);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
	border-color: #04c #04c #002a80;
	border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: $white;
	text-shadow: 0 -1px 0 rgba(0,0,0,.25);
}
.datepicker table tr td.active.active,.datepicker table tr td.active.disabled,.datepicker table tr td.active.disabled.active,.datepicker table tr td.active.disabled.disabled,.datepicker table tr td.active.disabled:active,.datepicker table tr td.active.disabled:hover,.datepicker table tr td.active.disabled:hover.active,.datepicker table tr td.active.disabled:hover.disabled,.datepicker table tr td.active.disabled:hover:active,.datepicker table tr td.active.disabled:hover:hover,.datepicker table tr td.active.disabled:hover[disabled],.datepicker table tr td.active.disabled[disabled],.datepicker table tr td.active:active,.datepicker table tr td.active:hover,.datepicker table tr td.active:hover.active,.datepicker table tr td.active:hover.disabled,.datepicker table tr td.active:hover:active,.datepicker table tr td.active:hover:hover,.datepicker table tr td.active:hover[disabled],.datepicker table tr td.active[disabled] {
	background-color: #04c;
}
.datepicker table tr td.active.active,.datepicker table tr td.active.disabled.active,.datepicker table tr td.active.disabled:active,.datepicker table tr td.active.disabled:hover.active,.datepicker table tr td.active.disabled:hover:active,.datepicker table tr td.active:active,.datepicker table tr td.active:hover.active,.datepicker table tr td.active:hover:active {
	background-color: #039;
}
.datepicker table tr td span.focused,.datepicker table tr td span:hover {
	background: #eee;
}
.datepicker table tr td span.disabled,.datepicker table tr td span.disabled:hover {
	background: 0 0;
	color: #999;
	cursor: default;
}
.datepicker table tr td span.active,.datepicker table tr td span.active.disabled,.datepicker table tr td span.active.disabled:hover,.datepicker table tr td span.active:hover {
	background-color: #006dcc;
	background-image: -moz-linear-gradient(to bottom,#08c,#04c);
	background-image: -ms-linear-gradient(to bottom,#08c,#04c);
	background-image: -webkit-gradient(linear,0 0,0 100%,from(#08c),to(#04c));
	background-image: -webkit-linear-gradient(to bottom,#08c,#04c);
	background-image: -o-linear-gradient(to bottom,#08c,#04c);
	background-image: linear-gradient(to bottom,#08c,#04c);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
	border-color: #04c #04c #002a80;
	border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: $white;
	text-shadow: 0 -1px 0 rgba(0,0,0,.25);
}
.datepicker table tr td span.active.active,.datepicker table tr td span.active.disabled,.datepicker table tr td span.active.disabled.active,.datepicker table tr td span.active.disabled.disabled,.datepicker table tr td span.active.disabled:active,.datepicker table tr td span.active.disabled:hover,.datepicker table tr td span.active.disabled:hover.active,.datepicker table tr td span.active.disabled:hover.disabled,.datepicker table tr td span.active.disabled:hover:active,.datepicker table tr td span.active.disabled:hover:hover,.datepicker table tr td span.active.disabled:hover[disabled],.datepicker table tr td span.active.disabled[disabled],.datepicker table tr td span.active:active,.datepicker table tr td span.active:hover,.datepicker table tr td span.active:hover.active,.datepicker table tr td span.active:hover.disabled,.datepicker table tr td span.active:hover:active,.datepicker table tr td span.active:hover:hover,.datepicker table tr td span.active:hover[disabled],.datepicker table tr td span.active[disabled] {
	background-color: #04c;
}
.datepicker table tr td span.active.active,.datepicker table tr td span.active.disabled.active,.datepicker table tr td span.active.disabled:active,.datepicker table tr td span.active.disabled:hover.active,.datepicker table tr td span.active.disabled:hover:active,.datepicker table tr td span.active:active,.datepicker table tr td span.active:hover.active,.datepicker table tr td span.active:hover:active {
	background-color: #039;
}
.datepicker table tr td span.new,.datepicker table tr td span.old {
	color: #999;
}
.datepicker .datepicker-switch,.datepicker .next,.datepicker .prev,.datepicker tfoot tr th {
	cursor: pointer;
}
.datepicker .datepicker-switch:hover,.datepicker .next:hover,.datepicker .prev:hover,.datepicker tfoot tr th:hover {
	background: #eee;
}
.datepicker .next.disabled,.datepicker .prev.disabled {
	visibility: hidden;
}
.input-append.date .add-on,.input-prepend.date .add-on {
	cursor: pointer;
}
.input-append.date .add-on i,.input-prepend.date .add-on i {
	margin-block-start: 3px;
}
.input-daterange {
	input {
		text-align: center;
		&:first-child {
			-webkit-border-radius: 3px 0 0 3px;
			-moz-border-radius: 3px 0 0 3px;
			border-radius: 3px 0 0 3px;
		}
		&:last-child {
			-webkit-border-radius: 0 3px 3px 0;
			-moz-border-radius: 0 3px 3px 0;
			border-radius: 0 3px 3px 0;
		}
	}
	.add-on {
		display: inline-block;
		width: auto;
		min-width: 16px;
		height: 18px;
		padding: 4px 5px;
		font-weight: 400;
		line-height: 18px;
		text-align: center;
		text-shadow: 0 1px 0 $white;
		vertical-align: middle;
		background-color: #eee;
		border: 1px solid #ccc;
		margin-inline-start: -5px;
		margin-inline-end: -5px;
	}
}

/* ************** BOOTSTRAP DATEPICKER CLOSED ************** */

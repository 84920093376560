
/* ************** DATE RANGE PICKER OPEN ************** */

.daterangepicker {
	position: absolute;
	color: inherit;
	background-color: $white;
	border-radius: 4px;
	border: 1px solid #ddd;
	width: 278px;
	max-width: none;
	padding: 0;
	inset-block-start: 100px;
	inset-inline-start: 20px;
	z-index: 3001;
	display: none;
	font-family: arial;
	font-size: 15px;
	line-height: 1em;

	&:after {
		position: absolute;
		display: inline-block;
		content: '';
		inset-block-start: -6px;
		border-inline-end: 6px solid transparent;
		border-block-end: 6px solid $white;
		border-inline-start: 6px solid transparent;
	}

	&:before {
		position: absolute;
		display: inline-block;
		content: '';
		inset-block-start: -7px;
		border-inline-end: 7px solid transparent;
		border-inline-start: 7px solid transparent;
		border-block-end: 7px solid #ccc;
	}

	.calendar {
		display: block;
		max-width: 270px;
	}

	.calendar.left {
		padding: 8px 0 8px 8px;
	}

	.calendar.right {
		padding: 8px;
	}

	.calendar.single {
		.calendar-table {
			border: none;
		}
	}

	.calendar-table {
		.next {
			span {
				color: $white;
				border: solid #000;
				border-width: 0 2px 2px 0;
				border-radius: 0;
				display: inline-block;
				padding: 3px;
				transform: rotate(-45deg);
				-webkit-transform: rotate(-45deg);
			}
		}

		.prev {
			span {
				color: $white;
				border: solid #000;
				border-width: 0 2px 2px 0;
				border-radius: 0;
				display: inline-block;
				padding: 3px;
				transform: rotate(135deg);
				-webkit-transform: rotate(135deg);
			}
		}

		td {
			text-align: center;
			vertical-align: middle;
			min-width: 32px;
			width: 32px;
			height: 24px;
			line-height: 24px;
			font-size: 12px;
			border-radius: 4px;
			border: 1px solid transparent;
			white-space: nowrap;
			cursor: pointer;
		}

		th {
			text-align: center;
			vertical-align: middle;
			min-width: 32px;
			width: 32px;
			height: 24px;
			line-height: 24px;
			font-size: 12px;
			border-radius: 4px;
			border: 1px solid transparent;
			white-space: nowrap;
			cursor: pointer;
		}

		border: 1px solid $white;
		border-radius: 4px;
		background-color: $white;

		table {
			width: 100%;
			margin: 0;
			border-spacing: 0;
			border-collapse: collapse;
		}
	}

	td.available {
		&:hover {
			background-color: #d6dbea;
			border-color: transparent;
			color: inherit;
		}
	}

	th.available {
		&:hover {
			background-color: #d6dbea;
			border-color: transparent;
			color: inherit;
		}
	}

	td.week {
		font-size: 80%;
		color: #ccc;
	}

	th.week {
		font-size: 80%;
		color: #ccc;
	}

	td.off {
		background-color: $white;
		border-color: transparent;
		color: #999;
	}

	td.off.end-date {
		background-color: $white;
		border-color: transparent;
		color: #999;
	}

	td.off.in-range {
		background-color: $white;
		border-color: transparent;
		color: #999;
	}

	td.off.start-date {
		background-color: $white;
		border-color: transparent;
		color: #999;
	}

	td.in-range {
		background-color: #e2e2e9;
		border-color: transparent;
		color: #000;
		border-radius: 0;
	}

	td.start-date {
		border-radius: 4px 0 0 4px;
	}

	td.end-date {
		border-radius: 0 4px 4px 0;
	}

	td.start-date.end-date {
		border-radius: 4px;
	}

	th.month {
		width: auto;
	}

	option.disabled {
		color: #999;
		cursor: not-allowed;
		text-decoration: line-through;
	}

	td.disabled {
		color: #999;
		cursor: not-allowed;
		text-decoration: line-through;
	}

	select.monthselect {
		font-size: 12px;
		padding: 1px;
		height: auto;
		margin: 0;
		cursor: default;
		margin-inline-end: 2%;
		width: 56%;
	}

	select.yearselect {
		font-size: 12px;
		padding: 1px;
		height: auto;
		margin: 0;
		cursor: default;
		width: 40%;
	}

	select.ampmselect {
		width: 50px;
		margin: 0 auto;
		background: #eee;
		border: 1px solid #eee;
		padding: 2px;
		outline: 0;
		font-size: 12px;
	}

	select.hourselect {
		width: 50px;
		margin: 0 auto;
		background: #eee;
		border: 1px solid #eee;
		padding: 2px;
		outline: 0;
		font-size: 12px;
	}

	select.minuteselect {
		width: 50px;
		margin: 0 auto;
		background: #eee;
		border: 1px solid #eee;
		padding: 2px;
		outline: 0;
		font-size: 12px;
	}

	select.secondselect {
		width: 50px;
		margin: 0 auto;
		background: #eee;
		border: 1px solid #eee;
		padding: 2px;
		outline: 0;
		font-size: 12px;
	}

	.calendar-time {
		text-align: center;
		margin: 4px auto 0;
		line-height: 30px;
		position: relative;

		select.disabled {
			color: #ccc;
			cursor: not-allowed;
		}
	}

	.drp-buttons {
		clear: both;
		text-align: right;
		padding: 8px;
		border-block-start: 1px solid #ddd;
		display: none;
		line-height: 12px;
		vertical-align: middle;

		.btn {
			margin-inline-start: 8px;
			font-size: 12px;
			font-weight: 700;
			padding: 4px 8px;
		}
	}

	.drp-selected {
		display: inline-block;
		font-size: 12px;
		padding-inline-end: 8px;
	}

	.ranges {
		float: none;
		text-align: start;
		margin: 0;

		ul {
			list-style: none;
			margin: 0 auto;
			padding: 0;
			width: 100%;
		}

		li {
			font-size: 12px;
			padding: 8px 12px;
			cursor: pointer;

			&:hover {
				background-color: #eee;
			}
		}
	}
}

.daterangepicker.opensleft {
	&:before {
		inset-inline-end: 9px;
	}

	&:after {
		inset-inline-end: 10px;
	}
}

.daterangepicker.openscenter {
	&:after {
		inset-inline-start: 0;
		inset-inline-end: 0;
		width: 0;
		margin-inline-start: auto;
		margin-inline-end: auto;
	}

	&:before {
		inset-inline-start: 0;
		inset-inline-end: 0;
		width: 0;
		margin-inline-start: auto;
		margin-inline-end: auto;
	}
}

.daterangepicker.opensright {
	&:before {
		inset-inline-end: 9px;
	}

	&:after {
		inset-inline-end: 10px;
	}
}

.daterangepicker.drop-up {
	margin-block-start: -7px;

	&:before {
		inset-block-start: initial;
		inset-block-end: -7px;
		border-block-end: initial;
		border-block-start: 7px solid #ccc;
	}

	&:after {
		inset-block-start: initial;
		inset-block-end: -6px;
		border-block-end: initial;
		border-block-start: 6px solid $white;
	}
}

.daterangepicker.single {
	.calendar {
		float: none;
	}

	.daterangepicker {
		.ranges {
			float: none;
		}
	}

	.drp-selected {
		display: none;
	}
}

.daterangepicker.show-calendar {
	.calendar {
		display: block;
	}

	.drp-buttons {
		display: block;
	}

	.ranges {
		padding-block-start: 8px;
	}
}

.daterangepicker.auto-apply {
	.drp-buttons {
		display: none;
	}
}

.daterangepicker.show-ranges {
	.calendar.left {
		border-inline-start: 1px solid #ddd;
	}
}

.range_inputs {
	padding: 20px;
	border-block-start: 1px solid #ddd;
}

.daterangepicker_input {
	position: relative;

	i {
		position: absolute;
		inset-block-start: 12px;
		inset-inline-end: 12px;
	}
}

@media (min-width:564px) {
	.daterangepicker.ltr {
		.calendar.right {
			.calendar-table {
				border-inline-start: none;
				border-start-end-radius: 0;
				border-end-start-radius: 0;
			}

			margin-inline-start: 0;
		}

		.calendar.left {
			.calendar-table {
				border-inline-end: none;
				border-start-end-radius: 0;
				border-end-end-radius: 0;
				padding-inline-end: 8px;
			}

			clear: left;
			margin-inline-end: 0;
		}

		direction: ltr;
		text-align: start;

		.calendar {
			float: $float-left;
		}

		.ranges {
			float: $float-left;
		}
	}

	.daterangepicker.rtl {
		.calendar.left {
			.calendar-table {
				border-inline-start: none;
				border-start-end-radius: 0;
				border-end-start-radius: 0;
				padding-inline-start: 12px;
			}

			clear: right;
			margin-inline-start: 0;
		}

		.calendar.right {
			.calendar-table {
				border-inline-end: none;
				border-start-end-radius: 0;
				border-end-end-radius: 0;
			}

			margin-inline-end: 0;
		}

		direction: rtl;
		text-align: right;

		.calendar {
			text-align: right;
			float:$float-right;
		}

		.ranges {
			text-align: right;
			float:$float-right;
		}
	}

	.daterangepicker {
		width: auto;
		inset-inline-start: auto !important;
		inset-inline-end: 20px !important;

		.ranges {
			ul {
				width: auto;
			}
		}
	}

	.daterangepicker.single {
		.ranges {
			ul {
				width: 100%;
			}
		}

		.calendar.left {
			clear: none;
		}
	}

	.daterangepicker.single.ltr {
		.calendar {
			 float:$float-left;
		}

		.ranges {
			 float:$float-left;
		}
	}

	.daterangepicker.single.rtl {
		.calendar {
			float:$float-right;
		}

		.ranges {
			float:$float-right;
		}
	}
}

@media (min-width:730px) {
	.daterangepicker {
		.ranges {
			width: auto;
		}

		.calendar.left {
			clear: none !important;
		}
	}

	.daterangepicker.ltr {
		.ranges {
			 float:$float-left;
			border-inline-end: 1px solid #ddd;
		}
	}

	.daterangepicker.rtl {
		.ranges {
			float:$float-right;
		}
	}
}

/* ************** DATE RANGE PICKER CLOSED ************** */

/* ************** MULTIPLE SELECT OPEN ************** */

/**
 * @author zhixin wen <wenzhixin2010@gmail.com>
 */
.ms-parent {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 100% !important;

    .placeholder {
        height: 0;
        width: 0;
        border: 0;
    }
}

.ms-choice {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem 0.75rem;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $border;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    font-size: 14px;
    line-height: 13px;

    >span {
        position: absolute;
        inset-block-start: 13px;
        inset-inline-start: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        padding-inline-start: 18px;
        padding-inline-end: 22px;
        inline-size: inherit;
        text-align: initial;
        overflow: hidden;
    }

    >span.placeholder {
        color: #999;
        opacity: 0;
    }

    >div {
        position: absolute;
        inset-block-start: 7px;
        inset-inline-end: 5px;
        width: 20px;
        height: 25px;
        background: url('../plugins/multipleselect/multiple-select.png') left top no-repeat;
    }

    >div.open {
        background: url('../plugins/multipleselect/multiple-select.png') right top no-repeat;
    }
}

.ms-choice.disabled {
    background-color: #f3f4f7;
    background-image: none;
    border: 1px solid $border;
    cursor: default;
}

.ms-drop {
    width: 100%;
    overflow: hidden;
    display: none;
    margin-block-start: -3px;
    padding: 0;
    position: absolute;
    z-index: 10;
    background: $white;
    color: #000;
    border: 1px solid $border;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 0 0 4px 4px;

    ul {
        overflow: auto;
        margin: 0;
        padding: 5px 8px;

        >li {
            list-style: none;
            display: block;
            background-image: none;
            position: static;
            height: 30px;
            line-height: 30px;
            padding: 0 6px;
            border-radius: 4px;

            .disabled {
                opacity: .35;
                filter: Alpha(Opacity=35);
            }

            label {
                font-weight: normal;
                display: block;
                white-space: nowrap;
            }

            label.optgroup {
                font-weight: bold;
            }
        }

        >li.multiple {
            display: block;
            float: $float-left;

            label {
                width: 100%;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        >li.group {
            clear: both;
        }
    }

    input[type="checkbox"] {
        vertical-align: middle;
        vertical-align: middle;
        margin-inline-end: 6px;
        margin-block-end: 1px;
        margin-inline-start: 6px;
        width: 20px;
        height: 20px;
        background: $white;
        border: $white;
        box-shadow: none;
    }

    input[type="radio"] {
        margin-inline-end: 6px;
        margin-block-end: 1px;
        margin-inline-start: 6px;
        margin-block-start: 1px;
    }

    .ms-no-results {
        display: none;
    }

    .multiple {
        width: 90px !important;
    }

    .custom-checkbox {
        &:before {
            background: transparent;
        }

        .custom-control-input {
            &:indeterminate~.custom-control-label {
                &::before {
                    background-color: transparent;
                }
            }
        }
    }

    .custom-control-label {
        margin-block-end: 0;
        display: block;
        margin-block-start: 2px;
        line-height: 28px;

        &::before {
            position: absolute;
            inset-block-start: 0.4rem;
            display: block;
            width: 1rem;
            height: 1rem;
            pointer-events: none;
            content: "";
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: $white;
        }

        &::after {
            position: absolute;
            inset-block-start: 0.4rem;
            display: block;
            width: 1rem;
            height: 1rem;
            content: "";
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
        }
    }

    .custom-control-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
}

.ms-drop.bottom {
    inset-block-start: 100%;
    box-shadow: 0px 16px 18px rgba(4, 4, 7, 0.1);
}

.ms-drop.top {
    inset-block-end: 100%;
    box-shadow: 0px 16px 18px rgba(4, 4, 7, 0.1);
}

.ms-search {
    display: inline-block;
    margin: 0;
    min-height: 26px;
    padding: 4px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    z-index: 10000;
    -webkit-box-sizing: border-box;
    -khtml-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;

    input {
        width: 100%;
        height: auto !important;
        min-height: 24px;
        padding: 0 20px 0 5px;
        margin: 0;
        outline: 0;
        font-family: sans-serif;
        font-size: 1em;
        border: 1px solid $border;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background: #fff url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px;
        background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, -webkit-linear-gradient(center bottom, white 85%, #eeeeee 99%);
        background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, -moz-linear-gradient(center bottom, white 85%, #eeeeee 99%);
        background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, -o-linear-gradient(bottom, white 85%, #eeeeee 99%);
        background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, -ms-linear-gradient(top, #ffffff 85%, #eeeeee 99%);
        background: url('../plugins/multipleselect/multiple-select.png') no-repeat 100% -22px, linear-gradient(top, #ffffff 85%, #eeeeee 99%);
        -webkit-box-sizing: border-box;
        -khtml-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
    }
}

.ms-parent.form-control {
    width: 100% !important;
}

/* ************** MULTIPLE SELECT CLOSED ************** */
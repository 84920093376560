
/* ************** NOTIFY OPEN ************** */

/* Color setup */
/* You are free to change all of this */
/* notifit confirm */
#ui_notifIt {
    position: fixed;
    inset-block-start: 10px;
    inset-inline-end: 10px;
    cursor: pointer;
    overflow: hidden;
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    -wekbit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    z-index: 9999999;

    &:hover {
        opacity: 1 !important;
    }

    p {
        text-align: center;
        font-family: sans-serif;
        font-size: 14px;
        padding: 0;
        margin: 0;
    }
}

#notifIt_close {
    position: absolute;
    color: $white;
    inset-block-start: 0;
    padding: 0px 5px;
    inset-inline-end: 0;

    &:hover {
        background-color: $white-3;
    }
}

#ui_notifIt.success {
    background-color: #2dce89;
    color: white;
}

#ui_notifIt.error {
    background-color: #ff5b51;
    color: white;
}

#ui_notifIt.warning {
    background-color: #ecb403;
    color: white;
}

#ui_notifIt.info {
    background-color: #45aaf2;
    color: white;
}

#ui_notifIt.default {
    background-color: #ebecf1;
    color: #1a1630;
}

.notifit_confirm_bg {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
}

.notifit_prompt_bg {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
}

.notifit_confirm {
    * {
        font-family: sans-serif;
    }

    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    padding: 30px 30px 0px 30px;
    background-color: #EEE;
    border: 1px solid $black-1;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 2px 10px $black-2;
    box-shadow: 0px 2px 10px $black-2;
}

.notifit_prompt {
    * {
        font-family: sans-serif;
    }

    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    padding: 30px 30px 0px 30px;
    background-color: #EEE;
    border: 1px solid $black-1;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 2px 10px $black-2;
    box-shadow: 0px 2px 10px $black-2;
    text-align: center;
}

.notifit_confirm_accept {
    padding: 10px 20px;
    color: $white;
    border: 1px solid $black-1;
    background-color: #444;
    margin: 10px;
    outline: 0;
    cursor: pointer;
    display: inline-block;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    background-color: #9ACD32;

    &:hover {
        background-color: #ABDE43;
    }
}

.notifit_confirm_cancel {
    padding: 10px 20px;
    color: $white;
    border: 1px solid $black-1;
    background-color: #444;
    margin: 10px;
    outline: 0;
    cursor: pointer;
    display: inline-block;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    background-color: #FF4500;

    &:hover {
        background-color: #FF5611;
    }
}

.notifit_prompt_accept {
    padding: 10px 20px;
    color: $white;
    border: 1px solid $black-1;
    background-color: #444;
    margin: 10px;
    outline: 0;
    cursor: pointer;
    display: inline-block;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;

    &:hover {
        background-color: #666;
    }
}

.notifit_prompt_cancel {
    padding: 10px 20px;
    color: $white;
    border: 1px solid $black-1;
    background-color: #444;
    margin: 10px;
    outline: 0;
    cursor: pointer;
    display: inline-block;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;

    &:hover {
        background-color: #666;
    }
}

.notifit_confirm_message {
    text-align: center;
    margin-block-end: 20px;
}

.notifit_prompt_message {
    color: #444;
    margin-block-start: 0;
    text-align: center;
}

.notifit_prompt_input {
    text-align: center;
    font-family: sans-serif;
    font-size: 14px;
    width: 100%;
    padding: 10px;
    outline: none;
    border: 1px solid #AAA;
    color: #444;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

/* ************** NOTIFY CLOSED ************** */
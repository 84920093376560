
/* ************** BOOTSTRAP DATEPICKER OPEN ************** */

.datepicker {
	background-color: $white;
	border: 1px solid #e9ebfa;
	font-family: "Roboto", sans-serif;
	font-size: inherit;
	padding: 10px;
	margin: 1px 0 0;
	display: none;
	width: auto !important;
	z-index: 5 !important;
	direction: ltr;
	border-radius: 5px;
	box-shadow: 0px 16px 18px rgba(6, 10, 48, .1);
	&.dropdown-menu {
		color: #3b4863;
	}
	table {
		padding: 0px;
		margin: 0;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		border-collapse: inherit;
		tr {
			td {
				&.highlighted {
					background: #d9edf7;
					border-radius: 0;
				}
				&.today {
					&:hover {
						&:hover {
							color: #000;
						}
					}
					&.active {
						&:hover {
							color: $white;
						}
					}
				}
				span {
					display: block;
					width: 23%;
					height: 54px;
					line-height: 54px;
					float: $float-left;
					margin: 1%;
					cursor: pointer;
					-webkit-border-radius: 4px;
					-moz-border-radius: 4px;
					border-radius: 4px;
				}
			}
		}
	}
	td {
		text-align: center;
		padding: 6px 9px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		border: none;
		font-size: 12px;
		font-weight: 400;
		color: #070510;
		border-radius: 4px;
	}
	.datepicker-switch {
		text-align: center;
		color: #313e6a !important;
		font-weight: 700;
		font-size: 13px;
		text-transform: uppercase;
		letter-spacing: 1px;
		opacity: inherit !important;
		width: 145px;
	}
	th {
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 1px;
		padding: 6px 9px;
		color: #3b4863;
	}
	.cw {
		font-size: 10px;
		width: 12px;
		padding: 0 2px 0 5px;
		vertical-align: middle;
	}
}
.datepicker-inline {
	width: 220px;
}
.datepicker-rtl {
	direction: rtl;
	&.dropdown-menu {
		inset-inline-start: auto;
	}
	table {
		tr {
			td {
				span {
					float:$float-right;
				}
			}
		}
	}
}
.datepicker-dropdown {
	inset-block-start: 0;
	inset-inline-start: 0;
	&:before {
		content: '';
		display: inline-block;
		border-inline-start: 7px solid transparent;
		border-inline-end: 7px solid transparent;
		border-block-end: 7px solid #e9ebfa;
		border-block-start: 0;
		border-block-end-color: #e9ebfa;
		position: absolute;
	}
	&:after {
		content: '';
		display: inline-block;
		border-inline-start: 6px solid transparent;
		border-inline-end: 6px solid transparent;
		border-block-end: 6px solid #e9ebfa;
		border-block-start: 0;
		position: absolute;
	}
	&.datepicker-orient-left {
		&:before {
			inset-inline-start: 6px;
		}
		&:after {
			inset-inline-start: 7px;
		}
	}
	&.datepicker-orient-right {
		&:before {
			inset-inline-end: 6px;
		}
		&:after {
			inset-inline-end: 7px;
		}
	}
	&.datepicker-orient-bottom {
		&:before {
			inset-block-start: -7px;
		}
		&:after {
			inset-block-start: -6px;
		}
	}
	&.datepicker-orient-top {
		&:before {
			inset-block-end: -7px;
			border-block-end: 0;
			border-block-start: 7px solid #e9ebfa;
		}
		&:after {
			inset-block-end: -6px;
			border-block-end: 0;
			border-block-start: 6px solid #e9ebfa;
		}
	}
}
.datepicker .datepicker-switch:hover, .datepicker .prev:hover, .datepicker .next:hover, .datepicker tfoot tr th:hover {
	color: #3b4863 !important;
	background: transparent !important;
}
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
	background-color: transparent;
}
.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
	background: rgb(255,255,255,0.1);
	cursor: pointer;
}
.datepicker table tr td.old,
.datepicker table tr td.new {
	opacity: 5;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
	background: none;
	color: #3b4863;
	cursor: default;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
	background-color: #fde19a;
	background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
	background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
	border-color: #fdf59a #fdf59a #fbed50;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: #7e7da0;
}
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
	background-color: #fdf59a;
}
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
	background-color: #fbf069 \9;
}
.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
	background: #d6dcec;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}
.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
	background-color: #f3d17a;
	background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
	background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
	border-color: #f3e97a #f3e97a #edde34;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
	background-color: #f3e97a;
}
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
	background-color: #efe24b \9;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
	background-color: #9e9e9e;
	background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
	background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
	background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
	background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
	background-image: linear-gradient(to bottom, #b3b3b3, #808080);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
	border-color: #808080 #808080 #595959;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: $white;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
	background-color: #808080;
}
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
	background-color: #666666 \9;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
	background-color: #5965f9;
	background-image: -moz-linear-gradient(to bottom, #5965f9, #5965f9);
	background-image: -ms-linear-gradient(to bottom, #5965f9, #5965f9);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#7673e6), to(#7673e6));
	background-image: -webkit-linear-gradient(to bottom, #5965f9, #5965f9);
	background-image: -o-linear-gradient(to bottom, #5965f9, #5965f9);
	background-image: linear-gradient(to bottom, #5965f9, #5965f9);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7673e6', endColorstr='#7673e6', GradientType=0);
	border-color: #5965f9#7673e6#002a80;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: $white;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
	background-color: #5965f9;
}
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
	background-color: #003399 \9;
}
.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
	background: $primary !important;
	color: $white !important;
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
	background: none;
	color: #3b4863;
	cursor: default;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
	background-color: #3366ff;
	color: $white;
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
	background-color: #3366ff;
}
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
	background-color: #3366ff;
}
.datepicker table tr td span.old,
.datepicker table tr td span.new {
	color: #3b4863;
}
.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
	cursor: pointer;
	color: #3b4863;
	opacity: 5;
}
.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
	background: rgb(255,255,255,0.1);
	color: #3b4863;
}
.datepicker .prev.disabled,
.datepicker .next.disabled {
	visibility: hidden;
}
.input-append.date .add-on,
.input-prepend.date .add-on {
	cursor: pointer;
}
.input-append.date .add-on i,
.input-prepend.date .add-on i {
	margin-block-start: 3px;
}
.input-daterange {
	input {
		text-align: center;
		&:first-child {
			-webkit-border-radius: 3px 0 0 3px;
			-moz-border-radius: 3px 0 0 3px;
			border-radius: 3px 0 0 3px;
		}
		&:last-child {
			-webkit-border-radius: 0 3px 3px 0;
			-moz-border-radius: 0 3px 3px 0;
			border-radius: 0 3px 3px 0;
		}
	}
	.add-on {
		display: inline-block;
		width: auto;
		min-width: 16px;
		height: 18px;
		padding: 4px 5px;
		font-weight: normal;
		line-height: 18px;
		text-align: center;
		text-shadow: 0 1px 0 $white;
		vertical-align: middle;
		background-color: #d6dcec;
		border: 1px solid #7f88a5;
		margin-inline-start: -5px;
		margin-inline-end: -5px;
	}
}
.datepicker-days {
	.table-condensed {
		.active {
			day {
				background-color: #5965f9;
				color: $white;
			}
		}
	}
}
.datepicker .datepicker-switch, .datepicker .prev, .datepicker .next, .datepicker tfoot tr th {
	cursor: pointer;
	color: #3b4863;
	opacity: 5;
}
.dark-theme .datepicker .datepicker-switch, .datepicker .prev, .datepicker .next, .datepicker tfoot tr th {
	cursor: pointer;
	opacity: 5;
}
.dark-theme {
	.datepicker {
		background-color: #191f3a;
		box-shadow: 0 0 24px rgb(20 28 43 / 60%);
		border: 1px solid $white-1;
		.datepicker-switch {
			color: $white !important;
		}
		th {
			color: #ffffff;
		}
		td {
			border: 1px solid $white-1;
			background-color: #191f3a;
			color: rgba(255, 255, 255, 0.4);
		}
		.datepicker-calendar {
			td {
				a {
					&:hover {
						background-color: #5965f9;
						color: $white;
					}
				}
			}
		}
		.datepicker-months {
			td {
				border: none;
			}
		}
		.datepicker-years {
			td {
				border: none;
			}
		}
	}
	.datepicker-dropdown {
		&:before {
			content: '';
			display: inline-block;
			border-inline-start: 7px solid transparent;
			border-inline-end: 7px solid transparent;
			border-block-end: 7px solid #191f3a;
			border-block-start: 0;
			border-block-end-color: $black-2;
			position: absolute;
		}
		&:after {
			content: '';
			display: inline-block;
			border-inline-start: 6px solid transparent;
			border-inline-end: 6px solid transparent;
			border-block-end: 7px solid #191f3a;
			border-block-start: 0;
			position: absolute;
		}
		&.datepicker-orient-top {
			&:before {
				inset-block-end: -7px;
				border-block-end: 0;
				border-block-start: 7px solid #191f3a;
			}
			&:after {
				inset-block-end: -6px;
				border-block-end: 0;
				border-block-start: 6px solid #191f3a;
			}
		}
	}
}
.dark-theme .datepicker table tr td.old,
.datepicker table tr td.new {
	opacity: 5;
	color: #7f88a5;
}
.dark-theme .datepicker table tr td.day:hover, .datepicker table tr td.day.focused {
	background-color: #5965f9;
	color: $white;
}
.dark-theme .datepicker table tr td span:hover,
.dark-theme .datepicker table tr td span.focused {
	background: #292f4b !important;
}
.dark-theme .datepicker table tr td span.old, .datepicker table tr td span.new {
	color: #7f88a5;
}

/* ************** BOOTSTRAP DATEPICKER CLOSED ************** */

/* ************** RESPONSIVE BOOTSTRAP5 DATATABLES OPEN ************** */

table.dataTable.dtr-inline.collapsed {
    >tbody {
        >tr {
            >td.child {
                cursor: default !important;

                &:before {
                    display: none !important;
                }
            }

            >th.child {
                cursor: default !important;

                &:before {
                    display: none !important;
                }
            }

            >td.dataTables_empty {
                cursor: default !important;

                &:before {
                    display: none !important;
                }
            }

            >td.dtr-control {
                position: relative;
                padding-inline-start: 30px;
                cursor: pointer;

                &:before {
                    inset-block-start: 50%;
                    inset-inline-start: 5px;
                    height: 1em;
                    width: 1em;
                    margin-block-start: -9px;
                    display: block;
                    position: absolute;
                    color: white;
                    border: .15em solid white;
                    border-radius: 1em;
                    box-shadow: 0 0 .2em #444;
                    box-sizing: content-box;
                    text-align: center;
                    text-indent: 0 !important;
                    font-family: "Courier New", Courier, monospace;
                    line-height: 1em;
                    content: "+";
                    background-color: $primary;
                }
            }

            >th.dtr-control {
                position: relative;
                padding-inline-start: 30px;
                cursor: pointer;

                &:before {
                    inset-block-start: 50%;
                    inset-inline-start: 5px;
                    height: 1em;
                    width: 1em;
                    margin-block-start: -9px;
                    display: block;
                    position: absolute;
                    color: white;
                    border: .15em solid white;
                    border-radius: 1em;
                    box-shadow: 0 0 .2em #444;
                    box-sizing: content-box;
                    text-align: center;
                    text-indent: 0 !important;
                    font-family: "Courier New", Courier, monospace;
                    line-height: 1em;
                    content: "+";
                    background-color: $primary;
                }
            }
        }

        >tr.parent {
            >td.dtr-control {
                &:before {
                    content: "-";
                    background-color: #d33333;
                }
            }

            >th.dtr-control {
                &:before {
                    content: "-";
                    background-color: #d33333;
                }
            }
        }
    }
}

table.dataTable.dtr-inline.collapsed.compact {
    >tbody {
        >tr {
            >td.dtr-control {
                padding-inline-start: 27px;

                &:before {
                    inset-inline-start: 4px;
                    height: 14px;
                    width: 14px;
                    border-radius: 14px;
                    line-height: 14px;
                    text-indent: 3px;
                }
            }

            >th.dtr-control {
                padding-inline-start: 27px;

                &:before {
                    inset-inline-start: 4px;
                    height: 14px;
                    width: 14px;
                    border-radius: 14px;
                    line-height: 14px;
                    text-indent: 3px;
                }
            }
        }
    }
}

table.dataTable.dtr-column {
    >tbody {
        >tr {
            >td.dtr-control {
                position: relative;
                cursor: pointer;

                &:before {
                    inset-block-start: 50%;
                    inset-inline-start: 50%;
                    height: .8em;
                    width: .8em;
                    margin-block-start: -0.5em;
                    margin-inline-start: -0.5em;
                    display: block;
                    position: absolute;
                    color: white;
                    border: .15em solid white;
                    border-radius: 1em;
                    box-shadow: 0 0 .2em #444;
                    box-sizing: content-box;
                    text-align: center;
                    text-indent: 0 !important;
                    font-family: "Courier New", Courier, monospace;
                    line-height: 1em;
                    content: "+";
                    background-color: $primary;
                }
            }

            >th.dtr-control {
                position: relative;
                cursor: pointer;

                &:before {
                    inset-block-start: 50%;
                    inset-inline-start: 50%;
                    height: .8em;
                    width: .8em;
                    margin-block-start: -0.5em;
                    margin-inline-start: -0.5em;
                    display: block;
                    position: absolute;
                    color: white;
                    border: .15em solid white;
                    border-radius: 1em;
                    box-shadow: 0 0 .2em #444;
                    box-sizing: content-box;
                    text-align: center;
                    text-indent: 0 !important;
                    font-family: "Courier New", Courier, monospace;
                    line-height: 1em;
                    content: "+";
                    background-color: $primary;
                }
            }

            >td.control {
                position: relative;
                cursor: pointer;

                &:before {
                    inset-block-start: 50%;
                    inset-inline-start: 50%;
                    height: .8em;
                    width: .8em;
                    margin-block-start: -0.5em;
                    margin-inline-start: -0.5em;
                    display: block;
                    position: absolute;
                    color: white;
                    border: .15em solid white;
                    border-radius: 1em;
                    box-shadow: 0 0 .2em #444;
                    box-sizing: content-box;
                    text-align: center;
                    text-indent: 0 !important;
                    font-family: "Courier New", Courier, monospace;
                    line-height: 1em;
                    content: "+";
                    background-color: $primary;
                }
            }

            >th.control {
                position: relative;
                cursor: pointer;

                &:before {
                    inset-block-start: 50%;
                    inset-inline-start: 50%;
                    height: .8em;
                    width: .8em;
                    margin-block-start: -0.5em;
                    margin-inline-start: -0.5em;
                    display: block;
                    position: absolute;
                    color: white;
                    border: .15em solid white;
                    border-radius: 1em;
                    box-shadow: 0 0 .2em #444;
                    box-sizing: content-box;
                    text-align: center;
                    text-indent: 0 !important;
                    font-family: "Courier New", Courier, monospace;
                    line-height: 1em;
                    content: "+";
                    background-color: $primary;
                }
            }
        }

        >tr.parent {
            td.dtr-control {
                &:before {
                    content: "-";
                    background-color: #d33333;
                }
            }

            th.dtr-control {
                &:before {
                    content: "-";
                    background-color: #d33333;
                }
            }

            td.control {
                &:before {
                    content: "-";
                    background-color: #d33333;
                }
            }

            th.control {
                &:before {
                    content: "-";
                    background-color: #d33333;
                }
            }
        }
    }
}

table.dataTable {
    >tbody {
        >tr.child {
            padding: .5em 1em;

            &:hover {
                background: transparent !important;
            }

            ul.dtr-details {
                display: inline-block;
                list-style-type: none;
                margin: 0;
                padding: 0;

                >li {
                    border-block-end: 1px solid #efefef;
                    padding: .5em 0;

                    &:first-child {
                        padding-block-start: 0;
                    }

                    &:last-child {
                        border-block-end: none;
                    }
                }
            }

            span.dtr-title {
                display: inline-block;
                min-width: 75px;
                font-weight: bold;
            }
        }
    }
}

div.dtr-modal {
    position: fixed;
    box-sizing: border-box;
    inset-block-start: 0;
    inset-inline-start: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    padding: 10em 1em;

    div.dtr-modal-display {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        inset-block-end: 0;
        inset-inline-end: 0;
        width: 50%;
        height: 50%;
        overflow: auto;
        margin: auto;
        z-index: 102;
        overflow: auto;
        background-color: #f5f5f7;
        border: 1px solid black;
        border-radius: .5em;
        box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
    }

    div.dtr-modal-content {
        position: relative;
        padding: 1em;
    }

    div.dtr-modal-close {
        position: absolute;
        inset-block-start: 6px;
        inset-inline-end: 6px;
        width: 22px;
        height: 22px;
        border: 1px solid #eaeaea;
        background-color: #f9f9f9;
        text-align: center;
        border-radius: 3px;
        cursor: pointer;
        z-index: 12;

        &:hover {
            background-color: #eaeaea;
        }
    }

    div.dtr-modal-background {
        position: fixed;
        inset-block-start: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        z-index: 101;
        background: rgba(0, 0, 0, 0.6);
    }
}

div.dtr-bs-modal {
    table.table {
        tr {
            &:first-child {
                td {
                    border-block-start: none;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    div.dtr-modal {
        div.dtr-modal-display {
            width: 95%;
        }
    }
}

#example2 {
    .parent {
        animation: none;
    }
}


table.dataTable {
    margin: 0 auto;
    width: 100% !important;
}
/* ************** RESPONSIVE BOOTSTRAP5 DATATABLES CLOSED ************** */

/* ************** NOTIFY OPEN ************** */

/* jQuery Growl
 * Copyright 2015 Kevin Sylvestre
 * 1.3.5
 */
.ontop {
	z-index: 9999999;
	position: fixed;
}

#growls-default {
	z-index: 9999999;
	position: fixed;
	inset-block-start: 10px;
	inset-inline-end: 10px;
}

#growls-tl {
	z-index: 9999999;
	position: fixed;
	inset-block-start: 10px;
	inset-inline-start: 10px;
}

#growls-tr {
	z-index: 9999999;
	position: fixed;
	inset-block-start: 10px;
	inset-inline-end: 10px;
}

#growls-bl {
	z-index: 9999999;
	position: fixed;
	inset-block-end: 10px;
	inset-inline-start: 10px;
}

#growls-br {
	z-index: 9999999;
	position: fixed;
	inset-block-end: 10px;
	inset-inline-end: 10px;
}

#growls-tc {
	z-index: 9999999;
	position: fixed;
	inset-block-start: 10px;
	inset-inline-end: 10px;
	inset-inline-start: 10px;

	.growl {
		margin-inline-start: auto;
		margin-inline-end: auto;
	}
}

#growls-bc {
	z-index: 9999999;
	position: fixed;
	inset-block-end: 10px;
	inset-inline-end: 10px;
	inset-inline-start: 10px;

	.growl {
		margin-inline-start: auto;
		margin-inline-end: auto;
	}
}

#growls-cc {
	z-index: 9999999;
	position: fixed;
	inset-block-start: 50%;
	inset-inline-start: 50%;
	margin-inline-start: -125px;
}

#growls-cl {
	z-index: 9999999;
	position: fixed;
	inset-block-start: 50%;
	inset-inline-start: 10px;
}

#growls-cr {
	z-index: 9999999;
	position: fixed;
	inset-block-start: 50%;
	inset-inline-end: 10px;
}

.growl {
	opacity: 0.8;
	filter: alpha(opacity=80);
	position: relative;
	border-radius: 4px;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;

	.growl-close {
		cursor: pointer;
		float:$float-right;
		font-size: 14px;
		line-height: 18px;
		font-weight: normal;
		font-family: helvetica, verdana, sans-serif;
	}

	.growl-title {
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
		margin-block-end: 5px;
	}

	.growl-message {
		font-size: 13px;
		line-height: 18px;
	}
}

.growl.growl-incoming {
	opacity: 0;
	filter: alpha(opacity=0);
}

.growl.growl-outgoing {
	opacity: 0;
	filter: alpha(opacity=0);
}

.growl.growl-small {
	width: 200px;
	padding: 5px;
	margin: 5px;
}

.growl.growl-medium {
	width: 250px;
	padding: 10px;
	margin: 10px;
}

.growl.growl-large {
	width: 300px;
	padding: 15px;
	margin: 15px;
}

.growl.growl-default {
	color: $white;
	background: #2d2d2d;
}

.growl.growl-error {
	color: $white;
	background: #ff5b51;
}

.growl.growl-notice {
	color: $white;
	background: #2dce89;
}

.growl.growl-sucess {
	color: $white;
	background: #2dce89;
}

.growl.growl-warning {
	color: #000;
	background: #ecb403;
}

/* ************** NOTIFY CLOSED ************** */